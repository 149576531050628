import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { useMemo, useState } from 'react'

export const InputSelectComponentAnio = ({cambioDocProy, docProy}) => {

    const calcYears = (currentYear) => {
        let calcNextYears = [];
        for (let year = currentYear-2; year < currentYear+6; year++) {
            calcNextYears.push(year)
        }

        return calcNextYears
    }
    
    
    
    const currentYear = new Date().getFullYear();
    const nextYears = calcYears(currentYear)
    
    const [value, setValue] = useState(currentYear)


    const handleChange = (e) => {
        if (cambioDocProy != null) {
            cambioDocProy(e, 'anioObra');
        }
        if (docProy != null) {
            docProy.setDato(e)
        }
        setValue(e.target.value);
    }

    return (
        <FormControl fullWidth style={{ margin: 8, marginLeft: '7px'}}>
            <InputLabel id="select-anio-labe-id" style={{marginLeft: '16px', paddingBottom:'110px'}} >Año de la obra</InputLabel>
            <Select
                label='Año de la obra'
                labelId="select-anio-labe-id"
                id="select-anio-id"
                value={value}
                onChange={handleChange}
                displayEmpty
                name='anioObra'
                variant="outlined"
                style={{textAlign: 'start', }}
            >
                
                {
                    nextYears && nextYears.map((year) => (
                        <MenuItem key={`Anio-key-${year}`} value={year}>{year}</MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}