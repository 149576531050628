import React from 'react'
import { useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import dateFormat from 'dateformat'

import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
//Teble

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


//MUI Icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';


import axios from 'axios';

import firebase from 'firebase';
import { InputSelectComponent } from './AgregarProy/InputSelectComponent';
import { InputSelectComponentAnio } from './AgregarProy/InputSelectComponentAnio';
import { InputSelectUsuarios } from './AgregarProy/InputSelectUsuarios';
//Mis Funciones
// import {getUserId} from '../../utils/misFunciones'


const useStyles = makeStyles((theme) => ({

    divProyInfo:{
        margin: 'auto',
        textAlign: 'center',
        maxWidth: '900px'
    },
    paperPrincipal:{
        margin:'auto',
        padding: '20px',
        maxWidth: '1200px',
        textAlign:'center',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
    },
    divParticipantes: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 3,
        minHeight: '67px',
        justifyContent: 'center', 
        alignItems: 'center',
    },
    chipUser: {
        margin: 6,
    },
    table: {
        borderColor: '#D8D8D8',
        border: '2px solid',
      },
    tableConcepto: {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
        borderTop: '3px solid',
      },
    tableBoton: {
        borderColor: '#FFFFFF',
        border: '2px solid',
       },
      tableHead: {
        borderColor: '#5B9FED',
        border: '2px solid',
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
      },
      tableCell: {
        color: '#FFFFFF',
        padding: 0,
        
      },
      tableCellBorder:{
        borderColor: '#D8D8D8',
        border: '2px solid'
      },
      tableCellButton:{
        borderColor: '',
        border: '0px'
      },
      tableCellMonto:{
        maxWidth: '100px'
      },
      tableInputMonto:{
          maxWidth: '100%'
      },
      iconButtonSubC:{
        padding: 5
      },
      iconButton:{
        padding: 15
      },
      divBotonGuardar:{
          padding: 20,
          margin: 30
      }

}));



export default function AgregarProy() {
    
    var docProyc = {
        Ffinal: Date.now(),
        Finicio: Date.now(),
        anioObra: new Date().getFullYear(),
        creadorDoc: firebase.auth().currentUser.uid,
        cvaObra: '',
        importContratoIva:'',
        importContrato: '',
        municipio: '',
        nombreObra: '',
        rfcContratista: '',
        nombreContratista: '',
        repreLegal: '',
        ubicacionObra: '',
        usuarios: [],
        avances: {
            financiero: 0,
            programado: 0,
            real: 0,
            lastUpdate: Date.now(),
        }
    }
    
    //Informacion del Proyecto
    const [DocProy, setDocProy] = useState(docProyc)

    var docResumenc = {
        desccatres: [
            {
                cvacata: '1',
                descripcion: '',
                precio: ''
            }
        ]
    }

    const [DocResumen, setDocResumen] = useState(docResumenc)

    var docDetallec = {
        cvacata1: [
            {
                cvacata: 1,
                cvadetalle: 1,
                descripcion: '',
                precio: '',
            }
        ]
    }

    const [DocDetalle, setDocDetalle] = useState(docDetallec)


    const classes = useStyles();
    
    const [participantesCambio, setparticipantesCambio] = useState(false)
    const [CambioConcepto, setCambioConcepto] = useState(false)
    const [RenderPage, setRenderPage] = useState(false)
    const [RenderConceptoValue, setRenderConceptoValue] = useState(false)
    
    useEffect(() => { 
        setRenderPage(true);
    }, [RenderPage]);
    useEffect(() => { 
        setRenderConceptoValue(true);
    }, [RenderConceptoValue]);
    useEffect(() => { 
        setparticipantesCambio(true);
    }, [participantesCambio]);
    useEffect(() => { 
        setCambioConcepto(true);
    }, [CambioConcepto]);


    const handleAgregarProyecto = () => {
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            const token = 'Token ' + idToken
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
                headers: { 'authorization': token }
            });

            const DatosNProyecto = {
                docProy: DocProy,
                docDetalle: DocDetalle,
                docResumen: DocResumen,
            }

            instance.post('/admin/agregarProyecto', DatosNProyecto)
                .then(function (response) {
                    console.log(response)
                    setDocProy(docProyc);
                    setDocResumen(docResumenc);
                    setDocDetalle(docDetallec);
                    setRenderPage(false);
                })
                .catch(function (error) {
                    console.log(error);
                });

        }).catch(function (error) {
            return error;
        });
    }

    // const nUsuarioChange = (event) =>{
    //     var ntext = event.target.value
    //     setnUsuarioAdd(ntext)
    // }
    const agregarParticipante = (participante) =>{

        if(!DocProy.usuarios.includes(participante) && (""+participante).length > 0){
            DocProy.usuarios[0]= participante;
            console.log(DocProy)
        }
    }
    // const eliminarParticipante = (pId) =>{
        
    //     setparticipantesCambio(false);
    //     DocProy.usuarios.splice(pId, 1);
    // }
    const cambioDocProy = (event, dato) =>{
        var texto = event.target.value;
        if(dato === 'Finicio' || dato === 'Ffinal'){

            var Fecha = new Date(texto);
            Fecha.setDate(Fecha.getDate() + 1)
            var FechaTimeStamp = Fecha.getTime();
            DocProy[dato] = FechaTimeStamp;

        }else{
            DocProy[dato] = (''+texto).toUpperCase();
            console.log(DocProy)
        
        }
    }
   



    //Conceptos
    const conceptoChange = ( event, id ) => {
        var descripcion = event.target.value
        DocResumen.desccatres[id].descripcion = descripcion; 
        console.log(DocResumen)
    }
    const agregarConcepto = () =>{

        setCambioConcepto(false);
        DocResumen.desccatres.push({
            cvacata: (DocResumen.desccatres.length+1).toString(),
            descripcion: '',
            precio: ''
        })
        DocDetalle['cvacata'+(DocResumen.desccatres.length)] = [
            {
                cvacata: DocResumen.desccatres.length,
                cvadetalle: 1,
                descripcion: '',
                precio: '',
            }
        ]
        console.log(DocDetalle)
    }
    const agregarSubConcepto = (cvacataID, indexDetalle) =>{
        
        setCambioConcepto(false);
        DocDetalle['cvacata'+cvacataID].push({
            cvacata: cvacataID,
            cvadetalle: indexDetalle,
            descripcion: '',
            precio: '',
        })

    }

    const SubConceptoDescripcionChange = (event, cvacataID, indexDetalle) =>{
        var text = event.target.value;
        DocDetalle['cvacata'+cvacataID][indexDetalle].descripcion = text;

    }
    const SubConceptoMontoChange = (event, cvacataID, indexDetalle) =>{
        var text = event.target.value;
        DocDetalle['cvacata'+cvacataID][indexDetalle].precio = text;
        calcularMontoTotal(cvacataID);
    }
    const reRender = () =>{
        setCambioConcepto(false);
    }
    const calcularMontoTotal = (cvacataID) =>{
        var cantidad = 0;
        DocDetalle['cvacata'+cvacataID].map((subconcepto) =>{
            if(isNaN(parseFloat(subconcepto.precio))){
                cantidad += 0;
            }else{
                cantidad += parseFloat(subconcepto.precio);
            }
        })      
        DocResumen.desccatres[cvacataID-1].precio = cantidad;
        setRenderConceptoValue(false);
        calcularMontoTotalProyecto();
    }
    const calcularMontoTotalProyecto = () =>{
        var cantidad = 0;
        DocResumen.desccatres.map((concepto) =>{
            cantidad += concepto.precio;
        })
        DocProy.importContrato = cantidad;
        console.log(DocProy.importContrato)
             
    }

    return (

       
        <div>

            {
                (()=>{ if(RenderPage){ return (

                <Paper className={classes.paperPrincipal}>
                <h1>Agregar Proyecto</h1>
                <div className={classes.divProyInfo}>

                    
                    <TextField
                    label='Nombre de la Obra'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'nombreObra')}
                    ></TextField>
                    
                    <InputSelectComponent cambioDocProy = {cambioDocProy}/>
                            
                    <TextField
                    label='Ubicacion de la obra'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'ubicacionObra')}
                    ></TextField>

                    <InputSelectComponentAnio cambioDocProy = {cambioDocProy}/>
                    
                    <TextField
                    label='CVA Obra'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'cvaObra')}
                    ></TextField>
                    
                    <TextField
                    label='Importe del contrato con IVA'
                    style={{ margin: 8 }}
                    variant='outlined'
                    type='text'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'importContratoIva')}
                    ></TextField>
                    
                    <TextField
                    label='RFC Contratista'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'rfcContratista')}
                    ></TextField>
                    
                    <TextField
                    label='Nombre del contratista'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'nombreContratista')}
                    ></TextField>
                    
                    <TextField
                    label='Representante legal'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(event) => cambioDocProy(event, 'repreLegal')}
                    ></TextField>

                    <TextField
                    label='Fecha de Inicio'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    type='date'
                    onChange={(event) => cambioDocProy(event, 'Finicio')}
                    defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
                    ></TextField>

                    <TextField
                    label='Fecha Final'
                    style={{ margin: 8 }}
                    variant='outlined'
                    fullWidth
                    type='date'
                    onChange={(event) => cambioDocProy(event, 'Ffinal')}
                    defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
                    ></TextField>
                            

                    <InputSelectUsuarios agregarParticipante={agregarParticipante}/>
                    
{/*                             
                            <h4>Participantes:</h4>

                            


                    <TextField
                    label='Agregar Participante'
                    style={{ margin: 8 }}
                    value={nUsuarioAdd}
                    onChange={(event) => nUsuarioChange(event)}
                    variant='outlined'
                    size='small'
                    ></TextField>
                    <IconButton type="submit" className={classes.iconButton} aria-label="agregarParticipante" size='medium' onClick={agregarParticipante}>
                        <PersonAddIcon />
                    </IconButton>
                    <div className={classes.divParticipantes}>
                        {(() => {
                            if(DocProy.usuarios.length <= 0){
                                return(
                                    <h4>Aun no hay participantes.</h4>
                                )
                            }
                        })()
                        }
                        {
                            participantesCambio && DocProy.usuarios.map((user, index) =>{
                                return(
                                    <Chip
                                        key={index}
                                        className={classes.chipUser}
                                        icon={<FaceIcon />}
                                        label={user}
                                        onDelete={(event) => eliminarParticipante(index)}
                                    />
                                )
                            })
                        }
                    </div> */}

                    <h3>Detalle del Proyecto</h3>

                    <TableContainer>
                                <Table className={classes.table} size='small'>
                                    <TableHead>
                                        <TableRow size='medium'>
                                            <TableCell key='descripcion' align='center' className={classes.tableCellBorder}>DESCRIPCION</TableCell>
                                            <TableCell key='monto' align='center' className={classes.tableCellMonto}>MONTO</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {
                                        CambioConcepto && DocResumen.desccatres.map((concepto, index) => {
                                            return(
                                                <TableBody>
                                                    <TableRow className={classes.tableConcepto}>
                                                        <TableCell  className={classes.tableCell}>
                                                            <TextField
                                                            key={index}
                                                            label={(index+1)+'.- Descripción'}
                                                            style={{ margin: 8 }}
                                                            defaultValue = {concepto.descripcion}
                                                            onChange = {(event) => conceptoChange(event, index)}
                                                            variant='standard'
                                                            size='small'
                                                            fullWidth
                                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                                            ></TextField>
                                                        </TableCell>
                                                        <TableCell align='right' className={classes.tableCellMonto}>
                                                            {/* <h3>{"$" + RenderConceptoValue ? concepto.precio : 0}</h3> */}
                                                            <TextField  
                                                            className={classes.tableInputMonto}
                                                            key={index+'.- Monto Total'}
                                                            label={(index+1)+'.- Monto Total sin IVA'}
                                                            style={{ margin: 8 }}
                                                            value = {RenderConceptoValue ? concepto.precio : 0}
                                                            variant='standard'
                                                            type='number'
                                                            inputProps={{
                                                                style: { textAlign: "right",}
                                                            }} 
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                            }} 
                                                            size='small'
                                                            fullWidth
                                                            ></TextField>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        DocDetalle['cvacata'+(index+1)].map((subconcepto, index) =>{
                                                            return(
                                                                <TableRow className={classes.table}>
                                                                    <TableCell className={classes.tableCell}>
                                                                        <TextField
                                                                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle}
                                                                            label={subconcepto.cvacata+'.'+subconcepto.cvadetalle + '.- Descripción'}
                                                                            style={{ margin: 8 }}
                                                                            defaultValue = {subconcepto.descripcion}
                                                                            variant='standard'
                                                                            size='small'
                                                                            fullWidth
                                                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                                                            onChange = {(event) => SubConceptoDescripcionChange(event, subconcepto.cvacata, (subconcepto.cvadetalle-1))}
                                                                        ></TextField>
                                                                    </TableCell>
                                                                    <TableCell align='right' className={classes.tableCellMonto}>
                                                                        <TextField  
                                                                            className={classes.tableInputMonto}
                                                                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle+'precio'}
                                                                            label={subconcepto.cvacata+'.'+subconcepto.cvadetalle+ ' Monto sin IVA'}
                                                                            style={{ margin: 8 }}
                                                                            defaultValue = {subconcepto.precio}
                                                                            variant='standard'
                                                                            type='number'
                                                                            inputProps={{
                                                                                style: { textAlign: "right",}
                                                                            }} 
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                            }} 
                                                                            size='small'
                                                                            fullWidth
                                                                            onChange = {(event) => SubConceptoMontoChange(event, subconcepto.cvacata, (subconcepto.cvadetalle-1))}
                                                                        ></TextField>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                    <TableRow className={classes.tableBoton}>
                                                        <TableCell key={index + 'concepto'} ></TableCell>
                                                        <TableCell key={index + 'boton'}align='right' className={classes.tableCellMonto} >
                                                            
                                                            {/*
                                                            <IconButton type="submit" className={classes.iconButtonSubC} aria-label="agregarConcepto" size='small' onClick={(event) => agregarSubConcepto((index+1), (DocDetalle['cvacata'+(index+1)].length+1) )}>
                                                                <AddCircleOutlineIcon/>
                                                            </IconButton>
                                                            */}
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                className={classes.button}
                                                                startIcon={<AddCircleOutlineIcon />}
                                                                onClick={(event) => agregarSubConcepto((index+1), (DocDetalle['cvacata'+(index+1)].length+1))}
                                                            >Agregar Subcapitulo</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        })
                                    }
                                </Table>
                    </TableContainer>
                    
                    {/* 
                    <div>
                        {
                            CambioConcepto && DocResumen.desccatres.map((concepto, index) => {
                                return(
                                    <TextField
                                    key={index}
                                    label={(index+1)}
                                    style={{ margin: 8 }}
                                    defaultValue = {concepto.descripcion}
                                    onChange = {(event) => conceptoChange(event, index)}
                                    variant='outlined'
                                    fullWidth
                                    ></TextField>
                                )
                            })
                        }

                    </div>
                    
                    <IconButton type="submit" className={classes.iconButton} aria-label="agregarConcepto" size='medium' onClick={agregarConcepto}>
                        <AddCircleOutlineIcon className={classes.inconButtonIcon} fontSize='large' />
                    </IconButton>
                    
                    
                    */}

                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={agregarConcepto}
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                    >Agregar Capitulo
                    </Button>

                    <div className={classes.divBotonGuardar}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAgregarProyecto}
                            className={classes.button}
                            startIcon={<SaveIcon />}
                        >Guardar Proyecto
                        </Button>
                    </div>
                </div>                
            </Paper>
            )}})()
            }

        </div>
    )
}
