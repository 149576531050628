//M-UI imports

import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


//import Fade from '@material-ui/core/Fade';
//M-UI Icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import GroupIcon from '@material-ui/icons/Group';
import Chip from '@material-ui/core/Chip';

import firebase from 'firebase/app';
import 'firebase/storage';




//Ventanas
import Perfil from './Perfil'
import AgregarProy from './director/AgregarProy'
import AgregarProy2 from './director/AgregarProy2'
import ListaUsuarios from './admin/ListaUsuarios';
import ListaAllProyectos from './admin/ListaAllProyectos'

import AvatarUser from './AvatarUser';

// import { html2pdf } from 'html2pdf.js';
import html2pdf from 'html2pdf.js'


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
    menuButton: {
      marginRight: theme.spacing(1),
      height: theme.spacing(1),
      width: theme.spacing(1),
      margin: 'auto',
      marginLeft: 0
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        width: '100%',
        padding: theme.spacing(0),
      },
      barMenu: {
        marginTop: 45,
      },
      menuChip: {
        margin: '0px',
        padding: '0px'
      }
  }));




  export default function Administrador( props ) {


    const theme = useTheme();
    const [component, setComponet] = React.useState(<h1>Cargando...</h1>);

    //Menu lateral izquierdo
    const [open, setOpen] = React.useState(false);
    // Avatar Menu
    const [anchorEl, setAnchorEl] = React.useState(null);    
    

    useEffect(() => {
      setComponet(<ListaAllProyectos admin={true} setComponet={setComponet}></ListaAllProyectos>)
    }, [])


    //Menu lateral
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    //Avatar menu
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };


    //Metodo para el cerrar la sesion del ususario
    const handleCerrarSesion = () => {
      setAnchorEl(null);
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    };

    const handlePerfil = () => {
      setAnchorEl(null);
      setComponet(<Perfil username={firebase.auth().currentUser.uid}></Perfil>);
    }

    const handleMenuClick = (id) => {
        console.log(id);
        switch(id){
            case 1:
                setComponet(<ListaAllProyectos admin={true} setComponet={setComponet}></ListaAllProyectos>);
                break;
            case 2:
                setComponet(<AgregarProy2/>);
                break;
            case 3:
                setComponet(<ListaUsuarios></ListaUsuarios>);
                break;
            default:
                break;
        }
    }


    const classes = useStyles();
        return (
            <div className={classes.root}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <Box display='flex' flexGrow={1}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <h3 className='h2bar'>AvanProy</h3>
                            {/* <Button color='inherit'>Inicio</Button> */}
                        </Box>
                        <Chip
                          size='medium'
                          avatar={<AvatarUser username={firebase.auth().currentUser.uid[0].toUpperCase()}></AvatarUser>}
                          label={firebase.auth().currentUser.uid}
                          clickable
                          className={classes.menuChip}
                          color="primary"
                          onClick={(event) => handleMenu(event)}
                          variant="default"
                        />
                        <Menu
                          id="menu-appbar"
                          className={classes.barMenu}
                          anchorEl={anchorEl}
                          getContentAnchorEl={null}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                  
                        >
                          <MenuItem onClick={handlePerfil}><PersonOutlineIcon />  Perfil</MenuItem>
                          <MenuItem onClick={handleCerrarSesion}><ExitToAppIcon />  Cerrar Sesión</MenuItem>
                        </Menu>                  
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem button onClick={(event) => handleMenuClick(1, event)}>
                            <ListItemIcon> <DescriptionIcon /> </ListItemIcon>
                            <ListItemText primary='Proyectos'/>
                        </ListItem>
                        <ListItem button onClick={(event) => handleMenuClick(2, event)}>
                            <ListItemIcon> <NoteAddIcon /> </ListItemIcon>
                            <ListItemText primary='Agregar Proyecto'/>
                        </ListItem>
                        <ListItem button onClick={(event) => handleMenuClick(3, event)}>
                            <ListItemIcon> <GroupIcon /> </ListItemIcon>
                            <ListItemText primary='Usuarios'/>
                        </ListItem>
                    </List>
                </Drawer>



                <main className={classes.content}>
                    {component}
                </main>
            </div>
            
        )
    }
