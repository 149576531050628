import { TextField } from '@material-ui/core'
import React from 'react'

export const InputText = ({label, name, type,docProy}) => {
    return (
        <TextField
            label={label}
            style={{ margin: 8 }}
            variant='outlined'
            fullWidth
            type={type ? type: 'text'}
            inputProps={{ style: { textTransform: "uppercase" } }}
            name = {name}
            onChange={(event) => docProy.setDato(event)}
        ></TextField>
    )
}
