import React from 'react'
import AppBar from '@material-ui/core/AppBar';

import '../pages/css/topbar.css'
import '../App.css'

function Topbar() { 
    return (
        <div>
            <AppBar>
                <div className='divbar'>
                <h3 className='h2bar'>Avanproy v1.0 - Control de avance de proyectos</h3> 
                {/* <h3 className='h2bar'>AvanCEAS v1.0 - Control de avance de proyectos</h3>  */}
                {/* <h2 className='h2bar ceaslong'>Comisión Estatal de Agua y Saneamiento</h2> 
                <h2 className='h2bar ceas'>CEAS</h2>  */}
                {/* <h3 className='h2bar'>Control de avance de proyectos</h3> */}
                </div>
            </AppBar>
        </div>
    )
}

export default Topbar
