import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'

export const InputSelectComponent = ({cambioDocProy, docProy}) => {

    const municipiosTabasco = [
        'Balancán', 'Cárdenas', 'Centla', 'Centro', 'Comalcalco', 'Cunduacán', 'Emiliano Zapata', 'Huimanguillo', 'Jalapa', 'Jalpa de Méndez', 'Jonuta', 'Macuspana', 'Nacajuca', 'Paraíso', 'Tacotalpa', 'Teapa', 'Tenosique',
    ]
    
    const [municipio, setMunicipio] = useState('')


    const handleChange = (e) => {
        if (cambioDocProy != null) {
            cambioDocProy(e, 'municipio');
        }
        if (docProy != null) {
            docProy.setDato(e)
        }
        setMunicipio(e.target.value);
    }

    return (
        <FormControl fullWidth style={{ margin: 8, marginLeft: '7px'}}>
            <InputLabel id="select-state-labe-id" style={{marginLeft: '16px', paddingBottom:'110px'}} >Municipio</InputLabel>
            <Select
                label='Municipio'
                labelId="select-state-labe-id"
                id="select-state-id"
                value={municipio}
                onChange={handleChange}
                displayEmpty
                name='municipio'
                variant="outlined"
                style={{textAlign: 'start', }}
            >
                {
                    municipiosTabasco && municipiosTabasco.map((estado) => (
                        <MenuItem key={`key-${estado}`} value={estado.toUpperCase()}>{estado.toUpperCase()}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}
