import React from 'react'
import { useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import dateFormat from 'dateformat'
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
//Teble

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


//MUI Icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';


import axios from 'axios';

import firebase from 'firebase';
import { InputSelectComponent } from './AgregarProy/InputSelectComponent';
import { InputSelectComponentAnio } from './AgregarProy/InputSelectComponentAnio';
import { InputSelectUsuarios } from './AgregarProy/InputSelectUsuarios';
import { DocProyecto } from './DocProyecto';
import { InputText } from './AgregarProy/InputText';
import { TableRowConcepto } from './AgregarProy/TableRowConcepto';
//Mis Funciones
// import {getUserId} from '../../utils/misFunciones'


const useStyles = makeStyles((theme) => ({
  divProyInfo:{
    margin: 'auto',
    textAlign: 'center',
    maxWidth: '900px',
    width: '100%',
    minWidth:'100px'
  },
  paperPrincipal:{
    margin:'auto',
    padding: '20px',
    maxWidth: '1800px',
    width:'95%',
    textAlign:'center',
  },
  divParticipantes: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 3,
    minHeight: '67px',
    justifyContent: 'center', 
    alignItems: 'center',
  },
  chipUser: {
    margin: 6,
  },
  table: {
    borderColor: '#D8D8D8',
    border: '2px solid',
  },
  tableConcepto: {
    borderColor: theme.palette.primary.main,
    border: '2px solid',
    borderTop: '3px solid',
  },
  tableBoton: {
    borderColor: '#FFFFFF',
    border: '2px solid',
    },
  tableHead: {
    borderColor: '#5B9FED',
    border: '2px solid',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
  tableCell: {
    color: '#FFFFFF',
    padding: 10,
    
  },
  tableCellBorder:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    minWidth: '200px'
  },

  
  tableCellBorderShort:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    minWidth: '30px',
    width: '30px'
  },
  
  tableCellBorderDesc:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    minWidth: '270px',
    width: '280px',
  },
  
  tableCellBorderMonto:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    minWidth: '130px',
    width: '130px'
  },
  
  tableCellBorderFecha:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    minWidth: '160px',
    width: '160px'
  },



  
  tableCellButton:{
    borderColor: '',
    border: '0px'
  },
  tableCellMonto:{
    maxWidth: '100px'
  },
  tableInputMonto:{
    maxWidth: '100%'
  },
  iconButtonSubC:{
    padding: 5
  },
  iconButton:{
    padding: 15
  },
  divBotonGuardar:{
    padding: 20,
    margin: 30
  }
      
}));
    
    
var docProy;

export default function AgregarProy() {

  const [newProyectInit, setNewProyectInit] = useState(true)
  const classes = useStyles();
  const [RenderPage, setRenderPage] = useState(true)
  const [resumen, setResumen] = useState([])
  

  // Se asigna la clase DocProyecto, para que se reinicien los datos cada vez que se carga la pagina
  useEffect(() => {
    docProy = new DocProyecto();
    setResumen(docProy.resumen.desccatres)
    console.log(docProy);
    console.log(docProy.Ffinal);
    setNewProyectInit(true)
  }, [newProyectInit])

  useEffect(() => {
    setRenderPage(true)
  }, [RenderPage])

  const handleBtnNewConcept = async() => {
    docProy.agregarConcepto();
    console.log(docProy);
    setResumen(docProy.resumen.desccatres)
  }

  const handleAgregarProyecto = () => {

    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async function (idToken) {
      const token = 'Token ' + idToken
      const instance = axios.create({
          baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
          headers: { 'authorization': token }
      });

      docProy.setProgramado(calcPorcentajeAvanceProgramado());

      const DatosNProyecto = {
          docProy: docProy.getDocInfo(),
          docDetalle: docProy.getDetalle(),
          docResumen: docProy.getResumen(),
      }

      DatosNProyecto.docProy.creadorDoc = firebase.auth().currentUser.uid;


      // console.log(DatosNProyecto);

      instance.post('/admin/agregarProyecto', DatosNProyecto)
      .then(function (response) {
        console.log(response)
        setNewProyectInit(false);
        setRenderPage(false)
      })
      .catch(function (error) {
          console.log(error);
      });

    }).catch(function (error) {
        return error;
    });




  }

  // Calcular el procentaje por los dias
  const calcPorcentajeAvanceProgramado = () => {
    // console.log('Hola');
    // console.log(docProy.Ffinal, ' ', docProy.Finicio );
    let initialDate = new Date(docProy.Finicio);
    let finalDate = new Date(docProy.Ffinal);
    let currentDate = new Date(initialDate);

    let data = docProy.getDetalle();
    let detalleKeys = Object.keys(data);

    let avancesArray = [];

    // console.log(new Date().toString());

    while (currentDate <= finalDate) {
      let avanceDay = 0

      detalleKeys.forEach((key) => {
        data[key].forEach((element) => {
          avanceDay += element.monto / docProy.importContratoIva * 1.16 *  Number(calcAvanceProgFechas(element.finicio, element.ftermino, currentDate))
        })
      })

      // console.log(currentDate.toLocaleDateString('en-US'), ': ', avanceDay.toFixed(2));

      avancesArray.push(avanceDay.toFixed(2))
      let newDate = currentDate.setDate(currentDate.getDate() + 1);
      currentDate = new Date(newDate);
    }
    // console.log(avancesArray);
    return avancesArray;
    // console.log(new Date().toString());
  }


  // ? CALCULAR EL PORCENTAJE DE AVANCE ENTRE DOS FECHAS Y FECHA DE AVANCE
  const calcAvanceProgFechas = (date1, date2, date3) => {
    
    const fInicio = new Date(new Date(date1).toLocaleDateString('en-US'));
    const fTermino = new Date(new Date(date2).toLocaleDateString('en-US'));
    const fActual = new Date(date3.toLocaleDateString('en-US'));

    let avance = 0;
    
    if (fActual > fTermino) {
      avance = 100;
      return avance.toFixed(2);
    }
    if (fInicio <= fActual && fActual <= fTermino) {
      const oneDay = 24 * 60 * 60 * 1000;
      const diasEntreInicioFinal = Math.round(Math.abs((fInicio - fTermino) / oneDay)) + 1;
      const diasTranscurridos = Math.round(Math.abs((fInicio - fActual) / oneDay))+1;
      const avance = 100 / diasEntreInicioFinal * diasTranscurridos;

      return avance.toFixed(2);
  
    }
    return avance.toFixed(2);
  }




  return (

       
    <div>

        {
          (()=>{ if(RenderPage){ return (

   
              
            <Paper className={classes.paperPrincipal}>
            <h1>Agregar Proyecto</h1>
            <div className={classes.divProyInfo}>

            <InputText label='Nombre de la Obra' name = 'nombreObra' docProy={docProy}/>
            
            <InputSelectComponent docProy = {docProy}/>
            
            <InputText label='Ubicacion de la obra' name = 'ubicacionObra' docProy={docProy}/>                            

            <InputSelectComponentAnio docProy = {docProy}/>
            
            <InputText label='CVA Obra' name = 'cvaObra' docProy={docProy}/>                            
            <InputText label='Importe del contrato con IVA' name = 'importContratoIva' type='number' docProy={docProy}/>                            
            <InputText label='RFC Contratista' name = 'rfcContratista' docProy={docProy}/>                            
            <InputText label='Nombre del contratista' name = 'nombreContratista' docProy={docProy}/>                                               
            <InputText label='Representante legal' name = 'repreLegal' docProy={docProy}/>                                               
            
            <TextField
            label='Fecha de Inicio'
            style={{ margin: 8 }}
            variant='outlined'
            fullWidth
            type='date'
            name = 'Finicio'
            onChange={(event) => docProy.setDato(event)}
            defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
            ></TextField>

            <TextField
            label='Fecha Final'
            style={{ margin: 8 }}
            variant='outlined'
            fullWidth
            type='date'
            name = 'Ffinal'
            onChange={(event) => docProy.setDato(event)}
            defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
            ></TextField>
                    

            <InputSelectUsuarios docProy={docProy}/>
    
            <h3>Detalle del Proyecto</h3>
            </div>    
            <Paper sx={{ overflow: 'hidden' }} style={{width: '100%', margin: '0px', padding: '0px'}}>
              <TableContainer>
                <Table className={classes.table} size='small'>
                  <TableHead>
                    <TableRow size='medium'>
                      <TableCell key='clave' align='center' className={classes.tableCellBorderShort}>CLAVE</TableCell>
                      <TableCell key='descripcion' align='center' className={classes.tableCellBorderDesc}>DESCRIPCION</TableCell>
                      <TableCell key='unidad' align='center' className={classes.tableCellBorderShort}>UNIDAD</TableCell>
                      <TableCell key='cantidad' align='center' className={classes.tableCellBorderShort}>CANTIDAD</TableCell>
                      <TableCell key='punitario' align='center' className={classes.tableCellBorderMonto}>PUnitario</TableCell>
                      <TableCell key='monto' align='center' className={classes.tableCellBorderMonto}>MONTO</TableCell>
                      <TableCell key='finicio' align='center' className={classes.tableCellBorderFecha}>Finicio</TableCell>
                      <TableCell key='ftermino' align='center' className={classes.tableCellBorderFecha}>Ftermino</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resumen.map((concepto, index) => (<TableRowConcepto key={`CONCEPTO-KEY-${index}`} docProy={docProy} index={index} classes={classes}/>))}
                  </TableBody>
                </Table>
              </TableContainer>  
            </Paper>
                

            <Button
              variant="contained"
              color="primary"
              size="medium"
              style={{ margin: 25 }}
              onClick={handleBtnNewConcept}
              className={classes.button}
              startIcon={<AddCircleOutlineIcon />}
              >Agregar Capitulo
            </Button>

            <div className={classes.divBotonGuardar}>
                <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleAgregarProyecto}
                className={classes.button}
                startIcon={<SaveIcon />}
                >Guardar Proyecto
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={calcPorcentajeAvanceProgramado}
                className={classes.button}
                startIcon={<SaveIcon />}
                >Prueba
              </Button> */}
            </div>    
              
        </Paper>
        )}})()
        }

    </div>
  )
}
