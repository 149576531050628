import React, { useState, useEffect} from 'react'

import {getUserId, getUserId2} from '../../utils/misFunciones'

//Componentes
import InfoProyecto from './InfoProyecto';


//MUI
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase/app";
import "firebase/firestore";

//MUI Icons
import ListAltIcon from '@material-ui/icons/ListAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


//links
import dateFormat from "dateformat";
import Link from '@material-ui/core/Link';


import AvatarUser from '../AvatarUser';
import { ProyectoCartComponent } from '../ProyectoCartComponent';


const useStyles = makeStyles((theme) =>({
    root: {
      //maxWidth: 1080,
      margin: 10,
      border: '2px solid',
      padding: 0,
      textAlign: 0,
      borderRadius: 6,
      borderColor: '#E7EDF3',
      transition: '0.4s',
        '&:hover': {
      borderColor: theme.palette.primary.main,
      transform: 'scale(1.009)',
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.5);',
        },
    },
    media: {
      height: 140,
    },
    avatar_size: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        background: theme.palette.primary.main
    },
    grid_right: {
        float: 'right',
    },
    link: {
        display: 'flex',
      },
      icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
      },
      topInfoProy: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
      },
      progress: {
          position: 'absolute'
      },
      GridFechas: {
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap'
      },
      DatosProyTitulo: {
          margin: '50px'
      },
  }));

  dateFormat.i18n = {
    dayNames: [
      "Do",
      "Lu",
      "Ma",
      "Mi",
      "Ju",
      "Vi",
      "Sá",
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    monthNames: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
  };

export default function ListaProyectos( props ) {
    
    const {setComponet} = props
    const [mostrarLista, setMostrarLista] = useState(true);

    useEffect(() => {
        setMostrarLista(true)
    }, [props])

    const [proyectos, setproyectos] = useState([]);
    const [proyectoClic, setProyectoClic] = useState([]);
    
    /*
    const handleAbrirProy = (id) => {
        console.log(id);

    }
*/

    useEffect(() => {
        
        const unsubscribe = firebase.firestore().collection('proyectos').where("usuarios", "array-contains", props.userID).orderBy('anioObra').orderBy('cvaObra').onSnapshot((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => ({idDoc: doc.id, ...doc.data()}));
                // console.log(data);
                setproyectos(data);
            })
   
      return () => {
          unsubscribe()
        }
      }, [firebase])

      const handleListaClic = (event) => {
        event.preventDefault();
        setMostrarLista(true);
    };
    const handleProyectoClic = (idDoc, event) => {
        event.preventDefault();
        setMostrarLista(false);
        setProyectoClic(idDoc);
    };




    // console.log(proyectos)
    const classes = useStyles();

    if(mostrarLista){
        return (
            <div>
                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon/>}>
                <Typography color="textPrimary" className={classes.link}>
                    <ListAltIcon className={classes.icon} />
                    Lista Proyectos
                </Typography>
                </Breadcrumbs>
                <hr/>
                {                   
                    proyectos && proyectos.map(proyecto =>{
                        var date;
                    return(
                        
                        <ProyectoCartComponent key={`ProyectoCartComponent-key-${proyecto.idDoc}`} proyecto={proyecto} classes={classes} handleProyectoClic={handleProyectoClic} date={date} />
                    )  
                    })
                
                }            
                
            </div>
        )
    }else{
        return(
            
            <div>
                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon/>}>
                    <Link color="inherit" href='' className={classes.link} onClick={handleListaClic}>
                        <ListAltIcon className={classes.icon}/>
                        Lista Proyectos
                    </Link>
                    <Typography color="textPrimary" className={classes.link}>
                        <DescriptionIcon className={classes.icon} />
                        Proyecto
                    </Typography>
                </Breadcrumbs>
                <hr/>
                <InfoProyecto idDoc={proyectoClic} setComponet={setComponet}></InfoProyecto>    
            </div>
            
        )
    }
}
