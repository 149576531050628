import React, { useState, useEffect} from 'react'

import {getUserId, getUserId2} from '../../utils/misFunciones'

//Componentes
import InfoProyecto from '../usuario/InfoProyecto';
import InfoProyectoD from '../director/infoProyectoD';


//MUI
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase/app";
import "firebase/firestore";

import Button from '@material-ui/core/Button';
//MUI Icons
import ListAltIcon from '@material-ui/icons/ListAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';

//links
import dateFormat from "dateformat";
import Link from '@material-ui/core/Link';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import axios from 'axios'
import { ProgressBarComponent } from '../ProgressBarComponent';
import { ProyectoCartComponent } from '../ProyectoCartComponent';
import html2pdf from 'html2pdf.js';


const useStyles = makeStyles((theme) =>({
    root: {
      //maxWidth: 1080,
      margin: 10,
      border: '3px solid',
      padding: 0,
      textAlign: 0,
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2);',
      transition: '0.4s',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            transform: 'scale(1.009)',
            boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.5);',
            
      },
    },
    media: {
      height: 140,
    },
    avatar_size: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        background: theme.palette.primary.main,
    },
    grid_right: {
        float: 'right',
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    btnEliminar: {
        backgroundColor: '#EC5656',
        color: '#000',
        margin: 2,
        fontStyle: 'bold',
        '&:hover': {
            backgroundColor: '#C43131',
        },
    },
    topInfoProy: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    progress: {
        position: 'absolute'
    },
    GridFechas: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
    },
    DatosProyTitulo: {
        margin: '50px'
    },
  }));

  dateFormat.i18n = {
    dayNames: [
      "Do",
      "Lu",
      "Ma",
      "Mi",
      "Ju",
      "Vi",
      "Sá",
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    monthNames: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
  };

export default function ListaAllProyectos( props ) {
    
    const [mostrarLista, setMostrarLista] = useState(true);
    const [cardShort, setCardShort] = useState(false)
    const [proyectos, setproyectos] = useState([]);
    const [proyectoClic, setProyectoClic] = useState([]);
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // });    
    

    useEffect(() => {
        setMostrarLista(true)
    }, [props])



    // Funcion para generar el PDF del reporte y descargarlo
    const handleDownloadReport = (e) => {
        
        let element = document.getElementById('ListaProyectosDiv');
        let opt = {
          margin:       0.3,
          filename:     `Reporte-${dateFormat(new Date(), "dd-mmmm-yyyy")}.pdf`,
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2 },
          jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
          pagebreak: { mode: 'avoid-all' }
        };
        
        // New Promise-based usage:
        html2pdf().set(opt).from(element).save().then(() => {
            setCardShort(false)
        })
    }




    //Dialogo para eliminar un proyecto
    const [open, setOpen] = useState(false);
    const [loading, setloading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirmEliminarProyecto = (proyId) => {
        setloading(true);
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            const token = 'Token ' + idToken
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
                headers: { 'authorization': token }
            });
            // console.log(proyId)
            const body = {
                proyId: proyId
            }
            instance.post('/admin/eliminarProyecto', body)
                .then(function (response) {
                    // console.log(response)
                    setOpen(false);
                    setMostrarLista(true);
                    setloading(false)
                })
                .catch(function (error) {
                    // console.log(error);
                    setOpen(false);
                    setloading(false)
                });

        }).catch(function (error) {
            return error;
        });
    }

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('proyectos').orderBy('anioObra').orderBy('cvaObra').onSnapshot((querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({ idDoc: doc.id, ...doc.data() }));
            // console.log('Nueva snaptshot lista proyectos');
            setproyectos(data);
        })
      return () => {
          unsubscribe()
        }
      }, [firebase])

    
    const handleListaClic = (event) => {
        event.preventDefault();
        setMostrarLista(true);
    };


    const handleProyectoClic = (idDoc, event) => {
        event.preventDefault();
        setMostrarLista(false);
        setProyectoClic(idDoc);
    };






    // console.log(proyectos)
    const classes = useStyles();

    if(mostrarLista){
        return (
            <div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon/>}>
                        <Typography color="textPrimary" className={classes.link}>
                            <ListAltIcon className={classes.icon} />
                            Lista Proyectos
                        </Typography>
                    </Breadcrumbs>
                    {/* <p onClick={e => setCardShort(!cardShort)}>Generar Reporte</p> */}
                    {
                        !cardShort &&
                            <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            // disabled={disableButton}
                            // style={styles.button}
                            // startIcon={<AddCircleIcon />}
                            onClick={e => setCardShort(!cardShort)}
                            >Generar Reporte</Button>
                    }
                    {
                        cardShort &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={!cardShort}
                                onClick={handleDownloadReport}
                            >Descargar Reporte</Button>
                    }
                </div>
                <hr />
                <section id='ListaProyectosDiv'>
                    {
                        cardShort &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#9D2449', color:'white'}}>
                                {/* <img src="https://is5-ssl.mzstatic.com/image/thumb/Purple125/v4/9c/3e/67/9c3e6797-0fb7-0e0c-08bf-0f672e927633/source/512x512bb.jpg" style={{height:'70px'}}/> */}
                                <h2>Comisión Estatal de Agua y Saneamineto</h2>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                                <h3>Reporte General de Obras y Proyectos</h3>
                                <p>Fecha: {dateFormat(new Date(), "dd mmmm, yyyy")}</p>
                            </div>
                        </div>
                    }
                {                   
                    proyectos && proyectos.map(proyecto => {
                        console.log(proyecto);
                        return (
                            <ProyectoCartComponent
                                key={`ProyectoCartComponent-key-${proyecto.idDoc}`}
                                proyecto={proyecto} setComponent={props.setComponent}
                                classes={classes} handleProyectoClic={handleProyectoClic}
                                // date={date}
                                short={cardShort}
                            />
                    )  
                    })
                
                }            
                </section>
                
            </div>
        )
    }else{
        return(
            
            <div>
                <div className={classes.topInfoProy}>
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon/>}>
                        <Link color="inherit" href='' className={classes.link} onClick={handleListaClic}>
                            <ListAltIcon className={classes.icon}/>
                            Lista Proyectos
                        </Link>
                        <Typography color="textPrimary" className={classes.link}>
                            <DescriptionIcon className={classes.icon} />
                            Proyecto
                        </Typography>
                    </Breadcrumbs>

                    

                    {
                        
                        props.admin && 
                        <Button 
                        onClick={handleClickOpen} 
                        className={classes.btnEliminar} 
                        startIcon={<DeleteForeverIcon />}>Eliminar Proyecto</Button>

                    }
                </div>
                

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"¿Seguro que desea eliminar el proyecto?"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Si continua, todos los datos del proyecto seran borrados.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleConfirmEliminarProyecto(proyectoClic)} color="primary" autoFocus disable={loading}>
                        Eliminar
                    {loading && (
                            <CircularProgress size={30} className={classes.progress}></CircularProgress>
                        )}
                    </Button>
                    </DialogActions>
                </Dialog>

                <hr/>
                {
                        
                        <InfoProyecto director={props.admin ? false : true} idDoc={proyectoClic} setComponet={props.setComponet}></InfoProyecto>  
                } 
            </div>
            
        )
    }
}
