import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'

export const TableHeadRow = ({classes, head}) => {
    return (
        <TableRow size='medium' className={classes.tableHead}>
            {
                head.map((title) => <TableCell align='center' className={classes.tableCellBorder}>{title}</TableCell>)
            }
            {/* <TableCell align='center' className={classes.tableCellBorder}>Estimación</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Período Inicio</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Período Final</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Importe</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>IVA</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Total</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Fecha Entrega</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Residente</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Construcción Tecnica</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Dirección Admin</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Dirección General</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Pagado</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Fecha de Pago</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Avance</TableCell>
            <TableCell align='center' className={classes.tableCellBorder}>Info</TableCell> */}
        </TableRow>
    )
}
