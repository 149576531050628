import { Button, Dialog,  DialogContent, DialogTitle, Fab, IconButton, TextField } from '@material-ui/core'
import { ChatBubbleOutline, CloseOutlined, SaveOutlined, Send,} from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { ChatMessage } from './ChatComponent/ChatMessage';
import firebase from 'firebase';
import axios from 'axios';


const styleForm = {
    div : {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '0px'
    },
    button: {width:'100%', marginTop:'20px'},
    input: {width:'100%'}
}

export const AliasProyectoComponent = ({docId, alias='', openAliasDialog = false, setOpenAliasDialog}) => {

    const [inputDisabled, setInputDisabled] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)

    const [inputValue, setInputValue] = useState(alias.toUpperCase())


    const handleForm = (e) => {
        setBtnDisabled(true);
        e.preventDefault();
        if (inputValue != '' && !btnDisabled) {
            console.log('Se guardo');
            pruebaGuardado();
        } else {
            setBtnDisabled(true);
        }
    }

    const handleInputChange = (e) => {
        if (btnDisabled) {
            setBtnDisabled(false)
        }
        setInputValue(e.target.value.toUpperCase())
        console.log(inputValue);
    }

    const handleCloseDialog = () => {
        setOpenAliasDialog(false)
    }

    const pruebaGuardado = () => {
        setInputDisabled(true);
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
          
          const token = 'Token ' + idToken
          const instance = axios.create({
            baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
            headers: {'authorization': token}
          });

          var aliasBody = {
            docId,
            alias: inputValue
          }

          instance.post('/user/agregarAlias', aliasBody)
                .then(function (response) {
                    console.log(response);
                    setInputDisabled(false)
                    setBtnDisabled(false)
                    setOpenAliasDialog(false)
                    
                })
                .catch(function (error) {
                  console.log(error);
                  setInputDisabled(false)
                  setBtnDisabled(false)
                });
            
            
        }).catch(function(error) {
          return error;
        });
      }




    return (
        <>
            <Dialog style={{minWidth:'200px'}} fullWidth={true} open={openAliasDialog} maxWidth='sm'>
                
                <DialogContent>                    
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom:'10px', padding:'0px', height:'40px' }}>
                        <h2 style={{padding:'0px', margin:'0px'}}>Modificar Alias</h2>
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                        >
                            <CloseOutlined />
                        </IconButton>
                    </div>
                    <form onSubmit={handleForm}>
                        <div style={styleForm.div}>
                            <TextField
                                style={styleForm.input}
                                label='Alias Contratista'
                                size='small'
                                variant='outlined'
                                value={ inputValue }
                                onChange={handleInputChange}
                                disabled={inputDisabled}
                                
                            />
                            <Button
                                style={styleForm.button}
                                type='submit'
                                variant="contained"
                                startIcon={<SaveOutlined />}
                                disabled={btnDisabled}
                                color='primary'
                            >Guardar</Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

        </>
    )
}
