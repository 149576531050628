import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import {useEffect, useState} from 'react';


export default function AvatarUser(props) {

    const [ImgUrl, setImgUrl] = useState("")
    const [finded, setfinded] = useState(false)
    const username = props.username


    // useEffect(() => {
      
    //     firebase.storage().ref().child('FotosPerfil/'+ props.username +'.jpg').getDownloadURL().then(function(url){
    //         setImgUrl(url);
    //         setfinded(true)
    //     }).catch(function(error){
       
    //     });
    // }, [ImgUrl])



    return (
        
        finded ?
        <Avatar src={ImgUrl} className={props.className} >{ props.username.toUpperCase()}</Avatar> : username != null ? <Avatar className={props.className} >{ (""+username[0]).toUpperCase()}</Avatar> : <Avatar className={props.className} ></Avatar>

    )
}
