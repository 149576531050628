export const authReducer = (state, action) => {
    switch (action.type) {
      case "SESION_INICIADA":{
        return { sessionState: true, ...action.payload }
      }
      case "SESION_FINALIZADA":{
        return { sessionState: false }
      }
  
      default:
        return state
    }
  }