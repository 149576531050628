import { Card, CardActionArea, CardContent, Grid } from '@material-ui/core'
import dateFormat from 'dateformat'
import React, { useEffect, useState } from 'react'
import { AvanceFinancieroComponent } from './director/AvanceFinanciero/AvanceFinancieroComponent'
import { ProgressBarComponent } from './ProgressBarComponent'

export const ProyectoCartComponent = ({proyecto, classes, handleProyectoClic, short=false, read=false}) => {

    const [avanceProgramado, setAvanceProgramado] = useState(-1)
    const [finalizado, setFinalizado] = useState(false);
    let date;

    useEffect(() => {
        let fechaInicio = new Date(new Date(proyecto.Finicio).toLocaleDateString('en-US'));
        let fechaTermino = new Date(new Date(proyecto.Ffinal).toLocaleDateString('en-US'));
        let fechaActual = new Date();
        // console.log(proyecto);
        if (fechaActual > fechaTermino) {
            setAvanceProgramado(proyecto.avances.programado.length - 1);
            setFinalizado(true)
            // console.log(proyecto.avances.programado.length);
        }
        if (fechaInicio <= fechaActual && fechaActual <= fechaTermino) {
            let oneDay = 24 * 60 * 60 * 1000;
            // console.log(fechaInicio);
            setAvanceProgramado(Math.round(Math.abs((fechaInicio - fechaActual) / oneDay)) - 1);
        }

    }, [proyecto])

    // useEffect(() => {
    //     // console.log('Dias: ', avanceProgramado);
    //     // console.log(proyecto.avances.programado);
    // }, [avanceProgramado])

    const avanceProgramadoValue = 0;

    // console.log(avanceProgramado, proyecto.avances.programado[avanceProgramado]);
    // console.log(avanceProgramado, proyecto.avances.programado);

    return (
        <Card className={classes.root} key={proyecto.idDoc} disabled>
            {
                !read ?
                <CardActionArea  onClick={(event) => handleProyectoClic(proyecto.idDoc, event)} >
                    <CardContentElements
                        proyecto={proyecto}
                        classes={classes}
                        handleProyectoClic={handleProyectoClic}
                        short={short}
                        avanceProgramado={avanceProgramado}
                        finalizado={finalizado}
                    />
                </CardActionArea>
                :
                    <CardContentElements
                        proyecto={proyecto}
                        classes={classes}
                        handleProyectoClic={handleProyectoClic}
                        short={short}
                        avanceProgramado={avanceProgramado}
                        finalizado={finalizado}
                    />
            }
        </Card>
    )
}


const CardContentElements = ({proyecto, classes, handleProyectoClic, short=false, read=false, date, avanceProgramado, finalizado}) => (
    <CardContent>
        <div>
            <h3>{('' + proyecto.anioObra + proyecto.cvaObra + '.- ' + proyecto.nombreObra).toUpperCase()}</h3>
        </div>
        {!short && <hr/>}
        {!short && <div>
            <h4 className='DatosProyTitulo'>UBICACIÓN: {('' + proyecto.ubicacionObra).toUpperCase()}, {('' + proyecto.municipio).toUpperCase()}</h4>
            <h4 className='DatosProyTitulo'>Importe de contrato con IVA: {proyecto.importContratoIva ? `${Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(proyecto.importContratoIva)}` : 'Sin dato'}</h4>
            <h4 className='DatosProyTitulo'>Contratista: {proyecto.nombreContratista && ('' + proyecto.nombreContratista).toUpperCase()} / {proyecto.repreLegal && ('' + proyecto.repreLegal).toUpperCase()} {proyecto.aliasContratista && ('/ ALIAS: ' + proyecto.aliasContratista).toUpperCase() }</h4>
        </div>}
        <div>
            <hr />
            <div>
                {!short && <h3>Avances: {(proyecto.avances && proyecto.avances.lastUpdate ) && dateFormat(date = new Date(parseInt(proyecto.avances.lastUpdate) * 1), "dd mmmm, yyyy - h:MM TT ")}</h3>}
                <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '140px'}}>
                    <p>{(!isNaN(parseInt(proyecto.Finicio)) && proyecto.Finicio)
                        && dateFormat(date = new Date(parseInt(proyecto.Finicio) * 1), "dd/mm/yyyy")}</p>
                    <p>{(!isNaN(parseInt(proyecto.Ffinal)) && proyecto.Ffinal)
                        && dateFormat(date = new Date(parseInt(proyecto.Ffinal) * 1), "dd/mm/yyyy")}</p>
                </div>
                <ProgressBarComponent text='Real:' value={(proyecto['avances'] != null && proyecto.avances.real !=null ) ? proyecto.avances['real'] : 0} color={(((proyecto['avances'] != null && proyecto.avances.real !=null ) ? proyecto.avances['real'] : 0) >= ((proyecto['avances'] != null && proyecto.avances.programado[avanceProgramado] !=null ) ? proyecto.avances.programado[avanceProgramado] : 0))?'green':'red'}/>
                {/* <ProgressBarComponent text='Programado:' value={(proyecto['avances'] != null && proyecto.avances.programado !=null) ? proyecto.avances['programado'] : 0} color='blue'/> */}
                <ProgressBarComponent text='Programado:' value={
                    (avanceProgramado === -1) ? 0 :
                    (finalizado) ? 100 :
                    proyecto.avances.programado[avanceProgramado]} color='blue' />
                <ProgressBarComponent text='Financiero:' value={(proyecto['avances'] != null && proyecto.avances.financiero !=null ) ? proyecto.avances['financiero'] : 0} color='brown'/>
            </div>
        </div>
        <hr />
        {(proyecto.avances.comentario) &&
            <div>
                <h3>Comentario</h3>
                <p>{''+proyecto.avances.comentario}</p>
            </div>
        }
    </CardContent>
)