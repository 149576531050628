import React from 'react'
import Topbar from '../components/Topbar';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Container from '@material-ui/core/Container';


export default function Loading() {
    
    //const classes = useStyles();
    return (
        <div>
            <Topbar></Topbar>
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{minHeight: '70vh'}} > 
                <CircularProgress/>
            </Grid>
        </div>
    )
}
