import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { TableHeadRow } from './components/TableHeadRow';
import { TableBodyComponent } from './components/TableBodyComponent';
import { AgregarAvance } from './components/AgregarAvance';
import firebase from 'firebase';
import { EstimacionConceptos } from './components/EstimacionConceptos';


const useStyles = makeStyles((theme)=>({
  table: {
    borderColor: '#D8D8D8',
    border: '2px solid',
  },
  tableHead: {
    borderColor: theme.palette.secondary.main,
    border: '2px solid',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  tableCell: {
    color: theme.palette.secondary.text,
  },
  tableAbancesCellBorder:{
    width: '80px',
  },
  tableCellBorder:{
    borderColor: '#D8D8D8',
    border: '2px solid'
  },
  tableCellBorderColor:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    backgroundColor: theme.palette.secondary.main,
  },
  tableCellBorderAvance:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    width: '150px',
    minWidth: '50px',
    padding: 0,
  },
  tableInput:{
    textAlign: 'center',
    backgroundColor: '#E6E6E6',
    margin: 0,
    padding: 0,
    maxWidth: '80px',
  },
}));

console.log('hola');
let claims = {}


export const AvanceFinancieroComponent = ({ idDoc, docInfo, docCat }) => {
    
  const classes = useStyles();

  const [numAvances, setNumAvances] = useState(0);
  const [mostrarEstimacionInfo, setMostrarEstimacionInfo] = useState({
    value: false,
    index: 0,
    data: {}
  })

  
  const [docFinanciero, setDocFinanciero] = useState({ exists: false, data: {}})
  
  const [showAddEstimacion, setshowAddEstimacion] = useState(false)

  const handleAddEstimacion = (e) => {
    setshowAddEstimacion(true)
  }
  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase.auth().currentUser.getIdTokenResult(true)
        .then((idTokenResult) =>{
          console.log(idTokenResult.claims);
          claims = idTokenResult.claims;
        })
      }
    });
  }, [])
  
  
    return (
      <div>
        <Paper>
          <h1>Avance financiero</h1>
          <h2>{(docInfo.anioObra + docInfo.cvaObra + ' .- ' + docInfo.nombreObra).toUpperCase()}</h2>
          <TableContainer>
            <Table className={classes.table} size='small'>
              <TableHead>
                <TableHeadRow
                  head={[
                    'Estimación',
                    'Período Inicio',
                    'Período Final',
                    'Importe',
                    'IVA',
                    'Total',
                    'Fecha Entrega',
                    'Residente',
                    'Construcción Tecnica',
                    'Dirección Admin',
                    'Dirección General',
                    'Pagado',
                    'Fecha de Pago',
                    'Avance',
                    'Info',
                  ]} 
                  classes={classes} />
              </TableHead>
              <TableBody>
              <TableBodyComponent
                classes={classes}
                idDoc={idDoc}
                setNumAvances={setNumAvances}
                importContratoIva={docInfo.importContratoIva}
                claims={claims}
                mostrarEstimacionInfo={mostrarEstimacionInfo}
                setMostrarEstimacionInfo={setMostrarEstimacionInfo}
                setshowAddEstimacion={setshowAddEstimacion}
                docFinanciero={docFinanciero}
                setDocFinanciero={setDocFinanciero}
              />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {mostrarEstimacionInfo.value &&
          <EstimacionConceptos
            docCat={docCat}
            useStyles={useStyles}
            mostrarEstimacionInfo={mostrarEstimacionInfo}
          />
        }
        
        {
          (!mostrarEstimacionInfo.value && !showAddEstimacion) &&
          <div style={{width: '', display:'flex', justifyContent:'center', padding:'50px'}}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAddEstimacion}
            >{'Crear Estimacion ' + numAvances}</Button>
          </div>
        }
        {(claims.usuarioResidente && !mostrarEstimacionInfo.value && showAddEstimacion) &&
          <AgregarAvance
            idDoc={idDoc}
            numAvances={numAvances}
            importContratoIva={docInfo.importContratoIva}
            docCat={docCat} useStyles={useStyles}
            setshowAddEstimacion={setshowAddEstimacion}
            docFinanciero={docFinanciero}
          />
        }
      </div>
    )
}
