import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { TableHeadRow } from './components/TableHeadRow';
import { TableBodyComponent } from './components/TableBodyComponent';
import { AgregarAvance } from './components/AgregarAvance';
import firebase from 'firebase';
import { EstimacionConceptos } from './components/EstimacionConceptos';
import { CancelarConcepto } from './components/CancelarConcepto';
import { TablaConceptosTerminados } from './components/CancelarConceptoTable/TablaConceptosTerminados';


const useStyles = makeStyles((theme)=>({
  table: {
    borderColor: '#D8D8D8',
    border: '2px solid',
  },
  tableHead: {
    borderColor: theme.palette.secondary.main,
    borderColor:'#D8D8D8',
    border: '2px solid',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    fontWeight: 'bold',
  },
  tableCell: {
    color: theme.palette.secondary.text,
  },
  tableAbancesCellBorder:{
    width: '80px',
  },
  tableCellBorder:{
    borderColor: '#D8D8D8',
    border: '2px solid'
  },
  tableCellBorderColor:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    backgroundColor: theme.palette.secondary.main,
  },
  tableCellBorderAvance:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    width: '150px',
    minWidth: '50px',
    padding: 0,
  },
  tableInput:{
    textAlign: 'center',
    backgroundColor: '#E6E6E6',
    margin: 0,
    padding: 0,
    maxWidth: '80px',
  },
}));

console.log('hola');
let claims = {}


export const CancelarConceptoComponent = ({ idDoc, docInfo, docCat }) => {
    
  const classes = useStyles();

  const [numAvances, setNumAvances] = useState(0);
  const [mostrarEstimacionInfo, setMostrarEstimacionInfo] = useState({
    value: false,
    index: 0,
    data: {}
  })

  
  const [docFinanciero, setDocFinanciero] = useState({ exists: false, data: {}})
  const [concluidoLenght, setConcluidoLenght] = useState(0)
  const [showAddEstimacion, setshowAddEstimacion] = useState(false)

  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase.auth().currentUser.getIdTokenResult(true)
        .then((idTokenResult) =>{
          console.log(idTokenResult.claims);
          claims = idTokenResult.claims;
        })
      }
    });
  }, [])


  
  useEffect(() => {
    const getDoc = firebase.firestore().collection('proyectos').doc(idDoc).collection('cat').doc('financiero')
        .onSnapshot(snapshot => {
            if (snapshot.exists) {
              setDocFinanciero({ exists: true, data: snapshot.data() });
              setConcluidoLenght(Object?.getOwnPropertyNames(snapshot.data()?.concluido)?.length)
            } else {
                setDocFinanciero({exists: false, data: {}});
            }
        })
    return () => {
      getDoc();
    }
  }, [firebase])

  console.log(concluidoLenght);

  return (
      <div>
        <Paper>
          <h1>Cierre anticipado de conceptos</h1>
          <h2>{(docInfo.anioObra + docInfo.cvaObra + ' .- ' + docInfo.nombreObra).toUpperCase()}</h2>
          {/* <TableContainer>
            <Table className={classes.table} size='small'>
              <TableHead>
                <TableHeadRow
                  classes={classes}
                  head={[
                    'Estimación',
                    'Período Inicio',
                    'Período Final',
                    'Importe',
                    'IVA',
                    'Total',
                    'Fecha Entrega',
                    'Residente',
                    'Construcción Tecnica',
                    'Dirección Admin',
                    'Dirección General',
                    'Pagado',
                    'Fecha de Pago',
                    'Avance',
                    'Info',
                  ]} 
                />
              </TableHead>
              <TableBody>
              <TableBodyComponent
                classes={classes}
                idDoc={idDoc}
                setNumAvances={setNumAvances}
                importContratoIva={docInfo.importContratoIva}
                claims={claims}
                mostrarEstimacionInfo={mostrarEstimacionInfo}
                setMostrarEstimacionInfo={setMostrarEstimacionInfo}
                setshowAddEstimacion={setshowAddEstimacion}
                docFinanciero={docFinanciero}
                setDocFinanciero={setDocFinanciero}
              />
              </TableBody>
            </Table>
          </TableContainer> */}
        </Paper>

        { (concluidoLenght > 0) &&
          <TablaConceptosTerminados
            docCat={docCat}
            useStyles={useStyles}
            docFinanciero={docFinanciero}
        />
        }

      
        <CancelarConcepto
          idDoc={idDoc}
          numAvances={numAvances}
          importContratoIva={docInfo.importContratoIva}
          docCat={docCat} useStyles={useStyles}
          // setshowAddEstimacion={setshowAddEstimacion}
          docFinanciero={docFinanciero}
        />
      
      </div>
    )
}
