import React from 'react'
import {/*initialState,*/ useEffect, useState} from 'react';
import { /*withStyles,*/ makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import dateFormat from 'dateformat'
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
/*
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
*/


import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
//import TextField from '@material-ui/core/TextField';


import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';


import AvatarUser from '../AvatarUser';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';



import axios from 'axios';
import { Grid, IconButton } from '@material-ui/core';
import { ProgressBarComponent } from '../ProgressBarComponent';
import { ChatComponent } from './ChatComponent';
import { AvanceFinancieroComponent } from '../director/AvanceFinanciero/AvanceFinancieroComponent';
import { CancelarConceptoComponent } from '../director/AvanceFinanciero/CancelarConceptoComponent';
import { AliasProyectoComponent } from './AliasProyectoComponent';
import { ProyectoCartComponent } from '../ProyectoCartComponent';

const useStyles = makeStyles((theme)=>({
  table: {
    borderColor: '#D8D8D8',
    border: '2px solid',
  },
  tableHead: {
    borderColor: theme.palette.secondary.main,
    border: '2px solid',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  tableCell: {
    color: theme.palette.secondary.text,
  },
  tableAbancesCellBorder:{
    width: '80px',
  },
  tableCellBorder:{
    borderColor: '#D8D8D8',
    border: '2px solid'
  },
  tableCellBorderAvance:{
    borderColor: '#D8D8D8',
    border: '2px solid',
    width: '150px',
    minWidth: '50px',
    padding: 0,
  },
  tableInput:{
    textAlign: 'center',
    backgroundColor: '#E6E6E6',
    margin: 0,
    padding: 0,
    maxWidth: '80px',
  },
  divContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: '20px',
    alignSelf: 'center',
  },
  buttonDelete: {
    margin: 'auto',
    marginRight: 0,
  },
  contenedorNombre:{
    textAlign: 'center',
    display: 'flex'
  },
  GridFechas: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap'
  },
  }));

let claims = {};

export default function InfoProyecto(props) {

  const classes = useStyles();
  // const [loading, setloading] = useState(false);
  let resultadoAvanceReal = 0;
  const [docInfo, setdocInfo] = useState(null);
  const [docCat, setdocCat] = useState(null);
  var nuevoAvance = {};
  const [nuevoAvanceC, setNuevoAvanceC] = useState(nuevoAvance);
  const [nuevoAvanceMain, setNuevoAvanceMain] = useState({})
  const [permitirGuardar, setPermitirGuardar] = useState(false);


  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.auth().currentUser.getIdTokenResult(true)
            .then((idTokenResult) =>{
                    console.log(idTokenResult.claims);
                    claims = idTokenResult.claims;
                } )
            }
    });
  }, [firebase])

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('proyectos').doc(props.idDoc)
      .onSnapshot(snapshot => {
        setdocInfo(snapshot.data());
        // solucion del error al intentar leer el valor de data().avances
        if (typeof(snapshot.data().avances) != 'undefined') {
          setNuevoAvanceMain(snapshot.data().avances) 
        }
        // console.log('Descarga. Doc id: ', props.idDoc);
      })
    const getCat = firebase.firestore().collection('proyectos').doc(props.idDoc).collection('cat')
      .onSnapshot((querySnapshot) => {
        let data = {}
        querySnapshot.docs.forEach(doc => {
          data[doc.id] = {...doc.data()};  
        });
          // console.log(data);
          // console.log("DECARGANDO DOCC");
        setdocCat(data);
        // console.log(docCat);
          setNuevoAvanceC(data['avance']);
      })
    return () => {
      unsubscribe();
      getCat();
    }
  }, [firebase])

      /*
      const estructura = {
        '1':{
          '1':{
            avances:[
              {
                cantidad: 20
              }
            ]
          }
        } 
      }

      */
  const pruebaGuardado = (event) => {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
      
      const token = 'Token ' + idToken
      const instance = axios.create({
        baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
        headers: {'authorization': token}
      });

      var docID = props.idDoc;

      //setNuevoAvanceMain((data) => { return {...data, real: resultadoAvanceReal} })

      var avanceData = {
        docId: docID, // id del documento
        docAvance: nuevoAvanceC, // Avances de los temas
        docAvanceMain: {
          lastUpdate: Date.now(),
          real: resultadoAvanceReal,
          programado: nuevoAvanceMain['programado'],
          financiero: nuevoAvanceMain['financiero']
        }, //Avance del documento principal
      }

      // console.log(token)
      // console.log(avanceData)

      instance.post('/user/agregarAvance', avanceData)
            .then(function (response) {
              console.log(response)
            })
            .catch(function (error) {
              console.log(error);
            });
      // console.log(JSON.stringify(nuevoAvanceC));
      setPermitirGuardar(false)


    }).catch(function(error) {
      return error;
    });
  }

  const handleGuardarAvance = (cvacata, cvadetalle, cantidadN, fechaN) => {

    if (nuevoAvance[cvacata] === undefined) {
      nuevoAvance[cvacata] = {}
    };

    nuevoAvance[cvacata][cvadetalle]=
    {
      avances:[
        {
          cantidad: cantidadN,
          fecha: fechaN
        }
      ]
    }
    // console.log(nuevoAvance);    
  };
      
      
  const handleCambioAvance = (event, cvacata, cvadetalle) => {
    
    // calcAvanceReal();
    docCat['avance'] = nuevoAvance;

    var nCantidad = event.target.value;
    if(nCantidad>100){
      nCantidad = 100;
    }
    if(nCantidad<0){
      nCantidad=0;
    }


    if (nuevoAvance[cvacata] === undefined) {
      nuevoAvance[cvacata] = {}
      // console.log('vaciandoo')
    };
    nuevoAvance[cvacata][cvadetalle]=
    {
      avances:[
        {
          cantidad: nCantidad,
          fecha: Date.now()
        }
      ]
    }

    setNuevoAvanceC(nuevoAvance);
    setPermitirGuardar(true);
  };
      
  
  const calcAvanceProgFechas = (date1, date2) => {
    
    const fInicio = new Date(new Date(date1).toLocaleDateString('en-US'));
    const fTermino = new Date(new Date(date2).toLocaleDateString('en-US'));
    const fActual = new Date();

    let avance = 0;
    
    if (fActual > fTermino) {
      avance = 100;
      return avance.toFixed(2);
    }
    if (fInicio <= fActual && fActual <= fTermino) {
      const oneDay = 24 * 60 * 60 * 1000;
      const diasEntreInicioFinal = Math.round(Math.abs((fInicio - fTermino) / oneDay)) + 1;
      // * CAMBIO EN DIAS TRANSCURRIDOS, SE QUITA UN (+1) QUE TENIA PARA SUMAR UN DIA
      const diasTranscurridos = Math.round(Math.abs((fInicio - fActual) / oneDay));
      const avance = 100 / diasEntreInicioFinal * diasTranscurridos;

      return avance.toFixed(2);
  
    }
    return avance.toFixed(2);
  }
  


  const {setComponet} = props;

  const handleFinancieroClick = () => {
    setComponet(<AvanceFinancieroComponent idDoc={props.idDoc} docInfo={docInfo} docCat={docCat}/>)
  }
  const handleOpenConceptCancelacion = () => {
    setComponet(<CancelarConceptoComponent idDoc={props.idDoc} docInfo={docInfo} docCat={docCat}/>)
  }
  

  //? DIALOGO ALIAS CONTRATISTA

  const [openAliasDialog, setOpenAliasDialog] = useState(false);

  if (docInfo != null) {
    
      var date;
        return ( 
            <div className='ParentDiv'>
              {(claims.directorGeneral && docInfo!=null) && <AliasProyectoComponent docId={props.idDoc} openAliasDialog={openAliasDialog} setOpenAliasDialog={setOpenAliasDialog} alias={docInfo.aliasContratista}/>}

                {/* <div className={(classes.contenedorNombre + '').toUpperCase()}>
                  <h3>{(docInfo.anioObra + docInfo.cvaObra + ' .- '+docInfo.nombreObra).toUpperCase()}</h3>
                </div>
                <hr/>
                
                <div>
                  <h4 className='DatosProyTitulo'>Ubicación: {(docInfo.ubicacionObra+', '+docInfo.municipio).toUpperCase()}</h4>
                  <h4 className='DatosProyTitulo'>Importe de contrato con IVA: {docInfo.importContratoIva ? `${Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(docInfo.importContratoIva)}` : 'Sin dato'}</h4>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {claims.directorGeneral && <IconButton size='small' aria-label="Avance financiero" style={{backgroundColor: '#9D2449'}} onClick={e => {setOpenAliasDialog(true)}}>
                      <PermIdentityIcon fontSize="small" style={{color: 'white'}}/>
                    </IconButton>}
                    <h4 className='DatosProyTitulo'>Contratista: {docInfo.nombreContratista && (docInfo.nombreContratista + '').toUpperCase()} / {docInfo.repreLegal && (docInfo.repreLegal + '').toUpperCase() } / ALIAS: {(docInfo.aliasContratista + '').toUpperCase()}</h4>
                  </div>
                </div>
            <hr /> */}
            
            <ProyectoCartComponent
              key={`ProyectoCartComponent-key-${props.idDoc}`}
              proyecto={docInfo}
              read={true}
              classes={classes}
              date={date}
            />
              
            

                <h4>Residente:</h4>
                <div>
                    {
                        docInfo.usuarios.map((user, i) =>{
                            return(<Chip
                                avatar={<AvatarUser username={user}></AvatarUser>}
                                label={user}
                                clickable
                                color="primary"
                                deleteIcon={<DoneIcon />}
                                variant="outlined"
                              />)
                        })
                    }
                </div>
                
            <div>
              {
                docCat!=null &&
                <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={handleOpenConceptCancelacion}
                >
                  Cerrar Concepto
                </Button>
              }
                
            </div>

                <hr/>

                

                {(() => {
                  // console.log('Empieza');
              let valorAvanceReal = 0;
              let valorAvanceProg = 0;
                    if (docCat!=null) {
                    return (
                        <Paper sx={{ overflow: 'hidden' }} style={{width: '100%', margin: '0px', padding: '0px'}}>
                                    
                            <TableContainer>
                                <Table className={classes.table} size='small'>
                                    <TableHead>
                                        <TableRow size='medium'>
                                            <TableCell align='center' className={classes.tableCellBorder}>CLAVE</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>DESCRIPCION</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>UNIDAD</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>PRECIO UNITARIO.</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>MONTO</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorderAvance}>AVANCE REAL</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>AVANCE PROG.</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>INICIO</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>TERMINO</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    
                            {
                                docCat['resumen'].desccatres.map((element) => {
                                  
                                  

                                    return(
                                        
                                        <TableBody>
                                            <TableRow className={classes.tableHead}>
                                                <TableCell colSpan={1} className={classes.tableCell}>{(element.clave + '').toUpperCase()}</TableCell>
                                                <TableCell colSpan={9} className={classes.tableCell}>{element.cvacata}.- {(element.descripcion+ '').toUpperCase()}</TableCell>
                                            </TableRow>
                                                { 
                                                    
                                                    // docCat.length-3
                                                    docCat['detalle']['cvacata'+element.cvacata].map(element => {

                                                      //handleGuardarAvance(element.cvacata, element.cvadetalle, nuevoAvance[element.cvacata][element.cvadetalle].avances[0].cantidad, nuevoAvance[element.cvacata][element.cvadetalle].avances[0].fecha );
                                                      if (docCat['avance'][element.cvacata]) {
                                                        // console.log('' + element.cvacata + element.cvadetalle + docCat['avance'][element.cvacata][element.cvadetalle].avances[docCat['avance'][element.cvacata][element.cvadetalle].avances.length-1].cantidad + docCat['avance'][element.cvacata][element.cvadetalle].avances[docCat['avance'][element.cvacata][element.cvadetalle].avances.length-1].fecha);
                                                        handleGuardarAvance(element.cvacata, element.cvadetalle, docCat['avance'][element.cvacata][element.cvadetalle].avances[docCat['avance'][element.cvacata][element.cvadetalle].avances.length-1].cantidad, docCat['avance'][element.cvacata][element.cvadetalle].avances[docCat['avance'][element.cvacata][element.cvadetalle].avances.length-1].fecha );
                                                        
                                                      }else{
                                                        
                                                        // console.log('' + element.cvacata +' '+ element.cvadetalle +' '+ docCat['avance'][element.cvacata][element.cvadetalle].avances[docCat['avance'][element.cvacata][element.cvadetalle].avances.length-1].cantidad + ' '+ docCat['avance'][element.cvacata][element.cvadetalle].avances[docCat['avance'][element.cvacata][element.cvadetalle].avances.length-1].fecha);
                                                        handleGuardarAvance(element.cvacata, element.cvadetalle,0,0 );
                                                      }

                                      //? SUMA DEL AVANCE REALLLLL 
                                                      let precio = element.monto;
                                                      let porcentaje = nuevoAvanceC[element.cvacata] ? nuevoAvanceC[element.cvacata][element.cvadetalle].avances[0].cantidad : 0;
                                                      // console.log(precio, porcentaje, 'resultado: ', (precio * porcentaje / 100) ); //? Precio y avance
                                                      valorAvanceReal += (precio * porcentaje);
                                                      valorAvanceProg += precio / docInfo.importContratoIva * 1.16 * calcAvanceProgFechas(element.finicio, element.ftermino )
                        
                                                        return(
                                                            <TableRow key={`${element.cvacata}.${element.cvadetalle}-${element.descripcion}`} className={classes.tableCellBorder}>
                                                                <TableCell className={classes.tableCellBorder}>{(''+element.clave).toUpperCase()}</TableCell>
                                                                <TableCell className={classes.tableCellBorder}>{element.cvacata}.{element.cvadetalle} {element.descripcion}</TableCell>
                                                                <TableCell className={classes.tableCellBorder}>{(''+element.unidad).toUpperCase()}</TableCell>
                                                                <TableCell align='center' className={classes.tableCellBorder}>{(''+element.cantidad).toUpperCase()}</TableCell>
                                                                <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(element.punitario))}</TableCell>
                                                                <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(element.monto))}</TableCell>
                                                            {props.director ? 
                                                              
                                                                <TableCell align='right' className={classes.tableCellBorderAvance} style={{paddingRight:'20px',}}>
                                                                  {nuevoAvanceC[element.cvacata] && Number(nuevoAvanceC[element.cvacata][element.cvadetalle].avances[0].cantidad).toFixed(2) + '%'}
                                                                </TableCell>
                                                              
                                                                : <TableCell align='center'
                                                                  className={classes.tableCellBorderAvance}>
                                                                      <Input className={classes.tableInput} 
                                                                        inputProps={{
                                                                          style: { textAlign: "right", 
                                                                          backgroundColor: '#E6E6E6',
                                                                        }}} 
                                                                        endAdornment={<InputAdornment position="end" className={classes.tableInput}>%</InputAdornment>}
                                                                        variant='filled' 
                                                                        size='small'
                                                                        type='number'
                                                                        min={0}
                                                                        max={100}
                                                                        onChange={(event) => handleCambioAvance(event, element.cvacata, element.cvadetalle)}
                                                                        value={nuevoAvanceC[element.cvacata] && nuevoAvanceC[element.cvacata][element.cvadetalle].avances[0].cantidad}>
                                                                  </Input>
                                                                </TableCell>}
                                                                <TableCell align='right' className={classes.tableCellBorder}>{calcAvanceProgFechas(element.finicio, element.ftermino) + '%'}</TableCell>
                                                                <TableCell className={classes.tableCellBorder}>
                                                                  {(!isNaN(parseInt(element.finicio)) && element.finicio)
                                                                  && dateFormat(date = new Date(parseInt(element.finicio) * 1), "dd/mm/yy")}
                                                                </TableCell>
                                                                
                                                                <TableCell className={classes.tableCellBorder}>
                                                                  {(!isNaN(parseInt(element.ftermino)) && element.ftermino)
                                                                  && dateFormat(date = new Date(parseInt(element.ftermino) * 1), "dd/mm/yy")}
                                                                </TableCell>
                                                                
                                                            </TableRow>
                                                        )
                                                      })
                                                    }
                                        </TableBody>
                                        )
                                
                                })
                                                
                            }
                            
                            {
                              (() => {
                                
                              //? SUMA DEL AVANCE REAL
                                // console.log('Suma avance real', valorAvanceReal);
                                // console.log('Importe Contrato iva', parseFloat(docInfo['importContratoIva']));
                                // console.log('Avance Real: ', resultadoAvanceReal);
                                resultadoAvanceReal = Math.round((valorAvanceReal / parseFloat(docInfo['importContratoIva']) * 1.16) * 100) / 100;

                              })()
                            }

                                </Table>
                            </TableContainer>
                            <br/>
                        <TableContainer>
                          <Table className={classes.table} size='small'>
                            <TableHead>
                              <TableRow className={classes.tableHead} size='medium'>
                                <TableCell className={classes.tableCell}>AVANCES</TableCell>
                                <TableCell className={classes.tableCell}>
                                  <div style={{display:'flex', justifyContent: 'space-between'}}>
                                    <p>{(!isNaN(parseInt(docInfo.Finicio)) && docInfo.Finicio)
                                          && dateFormat(date = new Date(parseInt(docInfo.Finicio) * 1), "dd/mm/yy")}</p>
                                    <p>{(!isNaN(parseInt(docInfo.Ffinal)) && docInfo.Ffinal)
                                          && dateFormat(date = new Date(parseInt(docInfo.Ffinal) * 1), "dd/mm/yy")}</p>
                                  </div>
                                </TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className={classes.tableCellBorder}>
                                <TableCell>
                                  
                                  <div style={{marginLeft:'0px'}}>Real</div>
                                </TableCell>
                                <TableCell>
                                  { (docInfo.avances != null) && <ProgressBarComponent text='' basic={true} value={(resultadoAvanceReal && resultadoAvanceReal) ? resultadoAvanceReal : 0} color={(((resultadoAvanceReal && resultadoAvanceReal) ? resultadoAvanceReal : 0) >= ((valorAvanceProg && docInfo.avances) ? valorAvanceProg : 0))?'green':'red'}/>}
                                </TableCell>
                                <TableCell className={classes.tableAbancesCellBorder} align='right'>
                                    {resultadoAvanceReal.toFixed(2) + '%'}
                                </TableCell>
                              </TableRow>
                              <TableRow className={classes.tableCellBorder}>
                                <TableCell>
                                  <div style={{marginLeft:'0px'}}>Programado</div>
                                </TableCell>
                                <TableCell>
                                  {(docInfo.avances != null) && <ProgressBarComponent text='' basic={true} value={(valorAvanceProg && docInfo.avances) ? valorAvanceProg.toFixed(2) : 0} color='blue' />}
                                </TableCell>
                                <TableCell className={classes.tableAbancesCellBorder} align='right'>
                                  {valorAvanceProg.toFixed(2) + '%'}
                                </TableCell>
                              </TableRow>
                              <TableRow className={classes.tableCellBorder}>
                                <TableCell className={classes.tableAbancesCellBorder}>
                                  
                                  <div style={{display:'flex', justifyContent:'center', alignItems:'left'}}>
                                    Financiero
                                    <IconButton size='small' aria-label="Avance financiero" color='primary' onClick={handleFinancieroClick}>
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {(docInfo.avances != null) && <ProgressBarComponent text='' basic={true} value={(docInfo.avances.financiero && docInfo.avances) ? docInfo.avances.financiero : 0} color='brown' />}
                                </TableCell>
                                <TableCell className={classes.tableAbancesCellBorder} align='right'>
                                  {Number(nuevoAvanceMain.financiero).toFixed(2) + '%'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                            
                        {!props.director ?
                            <div className={classes.divContainer}>

                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                startIcon={<SaveIcon />}
                                onClick={pruebaGuardado}
                                disabled={!permitirGuardar}
                              >
                                Guardar
                              </Button>
                          </div>
                          :
                          <>
                            <br/>
                            <br/>
                            <br/>
                          </>
                        }
                        </Paper>
                    )
                    } else {
                        return (
                            <div>Cargando...</div>
                        )
                    }
                    
                    
                })()}

                
            {/* {
              (docCat!= null) && <ChatComponent docId={props.idDoc} chatDoc={docCat['chat']} />
            } */}
            </div>
        )
    
    }else{
        return(
            <div></div>
        )
    }

}
