import React from 'react'
import { Button, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'

export const EstimacionConceptos = ({mostrarEstimacionInfo, docCat, useStyles}) => {

    
    const styles = {
        container: {
            display: 'flex',
            margin: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        paper: {
            display: 'flex',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '80%',
        },
    }
    
    const classes = useStyles();

    return (
      
        <>
            <br />
            <div style={styles.container}>
            <Paper style={styles.paper} sx={{ overflow: 'hidden' }}>
            <TableContainer>
                <Table className={classes.table} size='small'>
                    <TableHead>
                        <TableRow size='medium'>
                            <TableCell align='center' className={classes.tableCellBorder}>CLAVE</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>DESCRIPCION</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>UNIDAD</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD CATALOGO</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>PRECIO UNITARIO</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>MONTO CATALOGO</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD ESTIMA</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>MONTO ESTIMA</TableCell>
                        </TableRow>
                    </TableHead>

                    {
                    docCat['resumen'].desccatres.map( (concepto) => {
                        
                        let mostrarTitulo = -1;

                        return( 
                            <TableBody>

                                {
                                    docCat['detalle']['cvacata' + concepto.cvacata].map(subConcepto => {
                                        if ( typeof mostrarEstimacionInfo.data['cvacata' + subConcepto.cvacata + 'cvadetalle' + subConcepto.cvadetalle] != 'undefined') {
                                            
                                            mostrarTitulo++;
                                            return (
                                                <>
                                                    {
                                                        (mostrarTitulo === 0) &&    
                                                        <TableRow className={classes.tableHead}>
                                                            <TableCell colSpan={1} className={classes.tableCell}>{(concepto.clave + '').toUpperCase()}</TableCell>
                                                            <TableCell colSpan={7} className={classes.tableCell}>{concepto.cvacata}.- {(concepto.descripcion+ '').toUpperCase()}</TableCell>
                                                        </TableRow>
                                                    }
                                                    <TableRow className={classes.tableCellBorder}>
                                                        <TableCell className={classes.tableCellBorder}>{(''+subConcepto.clave).toUpperCase()}</TableCell>
                                                        <TableCell className={classes.tableCellBorder}>{subConcepto.cvacata}.{subConcepto.cvadetalle} {subConcepto.descripcion}</TableCell>
                                                                        <TableCell className={classes.tableCellBorder}>{(''+subConcepto.unidad).toUpperCase()}</TableCell>
                                                        <TableCell align='center' className={classes.tableCellBorder}>{(''+subConcepto.cantidad).toUpperCase()}</TableCell>
                                                        <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(subConcepto.punitario))}</TableCell>
                                                        <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(subConcepto.monto))}</TableCell>
                                                        <TableCell align='center'
                                                            className={classes.tableCellBorderAvance}>
                                                                {mostrarEstimacionInfo.data['cvacata' + subConcepto.cvacata + 'cvadetalle' + subConcepto.cvadetalle].cantidad}
                                                        </TableCell>
                                                        <TableCell align='right' className={classes.tableCellBorder}>{
                                                            '' + Intl.NumberFormat('en-US', { style: "currency", currency: "USD", }).format(Number(mostrarEstimacionInfo.data['cvacata' + subConcepto.cvacata + 'cvadetalle' + subConcepto.cvadetalle].montoEstima))
                                                        }</TableCell>
                                                    </TableRow>
                                                </>
                                                                 
                                            )
                                        }
                                    })
                                }
                            </TableBody>
                        )
                    })
                }
                
                </Table>
                </TableContainer>
            </Paper>
            </div>
            
        </>
    )
}

