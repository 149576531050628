import React, { useState } from 'react';

import '../App.css';

//Diseño
import Topbar from '../components/Topbar';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// import { UserContext } from "../utils/UserProvider"

//Components
import firebase from "firebase/app";
import 'firebase/auth';
import axios from 'axios';

import './css/login.css';



const useStyles = makeStyles({
    button: {
        position: 'relative'
    },
    progress: {
        position: 'absolute'
    }
});



function Login() {


    // const { state, dispatch } = useContext(UserContext);


      // Initialize Firebase
        let stateUser={
            username: '',
            password: ''
        };

       
     
    const classes = useStyles();

    const [errorP, seterrorP] = useState();
    const [errorU, seterrorU] = useState();
    const [loading, setloading] = useState(false);


    let loginSubmit = (event) => {
        //No refrescar al dar clic
        event.preventDefault();
        setloading(true);

        //Guardar los datos del form
        stateUser.username = document.getElementById("username").value;
        stateUser.password = document.getElementById("password").value;

        //Loading
        
        //Refrescar los mensajes del error
        seterrorP();
        seterrorU();


        //Crear el (json) para enviar los datos en la consulta
        const userData = {
            username: stateUser.username,
            password: stateUser.password,
        };
        
        //Si los campos estan llenos entrar al if
        if(stateUser.username && stateUser.password){
            
            //seterrorCampos();
            
        
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/'
              });
    
            instance.post('/login', userData)
              .then(function (response) {
                console.log(response.data);
                if(response.data.error){
                    if(response.data.type === "username"){ seterrorU(response.data.error);}
                    else{ seterrorP(response.data.error); }
                    setloading(false);
                } else {
                    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
                    .then(() => {
                        return firebase.auth().signInWithCustomToken(response.data.token);
                    })
                    // firebase.auth().signInWithCustomToken(response.data.token)
                    // .then((userCredential) => {
                    //     // Signed in//
                    //     //var user = userCredential.user;

                    //     console.log('dentro');
                    //     // dispatch({
                    //     //     type: 'SESION_INICIADA',
                    //     //     payload: {userCredential}
                    //     // })
                        
                    //     // history.replace('/')
                        
                    //     // console.log(userCredential);
                    //     // console.log(userCredential.user._lat);
                    //     // localStorage.setItem('FBIdToken', `Token ${userCredential.user._lat}`);
                    //     // window.location.href="/" 
                    //     // firebase.auth().currentUser.getIdToken()
                    //     // .then((idToken) => {
                    //     //     console.log(idToken);
                            
                    //     // })
                    //     // .catch((error) => {
                    //     //     // Error occurred.
                    //     // });
                    //     setloading(false);
                    // })
                    // .catch((error) => {
                    //     //Error
                    //     console.log(error);
                    // });
                }
              })
              .catch(function (error) {
                console.log(error);
                setloading(false);
              });

            console.log('Campos llenos');
        }else{
            console.log('Llene los campos');
            if(!stateUser.username){seterrorU("Llene el campo");}
            if(!stateUser.password){seterrorP("Llene el campo");}
            setloading(false);
        }

    }


    return (

        <div>
            <Topbar></Topbar>
            <Container component='main' maxWidth='xs' className="container-form">
                <Avatar className='avatar'>
                    <LockIcon color="primary" />
                </Avatar>
                <h2 className='texto-center'>Iniciar Sesion</h2>
                <form noValidate onSubmit={loginSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Usuario"
                        type="username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        helperText={errorU}
                        error={errorU ? true : false}
                        
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="password"
                        helperText={errorP}
                        error={errorP ? true : false}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="button-basic"
                        disabled={loading}
                    >Iniciar Sesion
                    {loading && (
                        <CircularProgress size={30} className={classes.progress}></CircularProgress>
                    )}
                    </Button>
                </form>
            </Container>
        </div>
    )
}

export default Login
