import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app';
import 'firebase/auth';
  
firebase.initializeApp({
  apiKey: "AIzaSyBQSB8eWUXfkvGrnTbMpQwLKnAl_y6gx1w",
  authDomain: "avanproy-test.firebaseapp.com",
  projectId: "avanproy-test",
  storageBucket: "avanproy-test.appspot.com",
  messagingSenderId: "117494701323",
  appId: "1:117494701323:web:0f6b52095a48dab2fed60e",
  measurementId: "G-V8PMQJ07KL"
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);



reportWebVitals();
