import React, { useEffect, useState } from 'react'
import firebase from 'firebase';

import './styles.css'

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@material-ui/core';

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: '100%',
            marginTop: '60px', 
        
        },

    },
    
     
};
export const InputSelectUsuarios = ({agregarParticipante, docProy}) => {

    const [usuariosData, setUsuariosData] = useState({})
    
    useEffect(() => {
        
        firebase.firestore().collection('userData').where('tipo', '==', 'usuario').get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setUsuariosData((usuariosData) => {
                    return {
                        ...usuariosData,
                        [doc.id]:{
                            user: doc.id,
                            nombre: doc.data().nombre
                        }
                    }
                })
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });

    }, [])

    
    const [personName, setPersonName] = React.useState('');

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          // On autofill we get a the stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        if (agregarParticipante != null) {
            agregarParticipante(event.target.value)
        }
        if (docProy != null) {
            docProy.agregarParticipante(event.target.value)
        }
    };


    return (
        <FormControl fullWidth style={{ margin: 8, textOverflow:'ellipsis'}}  >
                <InputLabel id="demo-multiple-checkbox-label" style={{paddingLeft:'18px', paddingTop: '0px'}}>Residente</InputLabel>
            <Select
                className='text'
                sx={{ }}
                style={{overflow: "hidden", textOverflow: "ellipsis", textAlign:'left', maxWidth:'100%'}}
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Residente" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                required
                >
                    {Object.keys(usuariosData).map((user) => (
                        <MenuItem key={user} value={user}>
                        <Checkbox checked={personName.indexOf(user) > -1} />
                        <ListItemText primary={`${usuariosData[user].user} (${usuariosData[user].nombre})`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
    )
}
