import React, { useEffect, useState } from 'react'
import firebase from 'firebase';
import { Checkbox, TableCell, TableRow, Button } from '@material-ui/core';
import dateFormat from 'dateformat';
import axios from 'axios';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
// import firebase from "firebase/app";
// import 'firebase/auth';





// console.log('hola');
// let claims = {}

export const TableBodyComponent = ({idDoc, classes, setNumAvances, importContratoIva, claims, mostrarEstimacionInfo, setMostrarEstimacionInfo, setshowAddEstimacion, docFinanciero, setDocFinanciero}) => {
    

    const [disabled, setDisabled] = useState(true)
    const [avance, setAvance] = useState([])
    let totalAvance = 0;


    const handleShowConcept = (e, index, doc) => {
        let dataConceptos = doc.conceptos ? doc.conceptos : {}
        setshowAddEstimacion(false)

        if (mostrarEstimacionInfo.value) {
            
            setMostrarEstimacionInfo(data => {
                return {
                    value: false,
                    index: 0,
                    data: {}
                }
            })
        } else {
            
            setMostrarEstimacionInfo(data => {
                return {
                    value: true,
                    index,
                    data: dataConceptos
                }
            })
            
        }
    }


    const calcAvance = (pagado, total) => {
        if (pagado) {
            return (total / Number(importContratoIva) * 100).toFixed(2)
        }
        return 0
    }

    
    const getAvance = (index, name, docValue) => {
        const found = avance.find(element => element.index === index && element[name] != null );
        if (found != null) {
            return found[name];
        } else {
            return docValue
        }
    }

    const handleAvanceChange = (index, name, value) => {
        
        let validarClaims = false;

        switch (name) {
            case 'valTecnica':
                if (claims.directorTecnico || claims.directorConstruccion || claims.AdminAccount) {
                    validarClaims = true
                }
                break;
            case 'valAdmin':
                if (claims.directorAdmin || claims.AdminAccount) {
                    validarClaims = true
                }
                break;
            case 'valGeneral':
                if (claims.directorGeneral || claims.AdminAccount) {
                    validarClaims = true                    
                }
                break;
            case 'pagado':
                if (claims.pagosEgresos || claims.AdminAccount) {
                    validarClaims = true
                }
                break;
        
            default:
                break;
        }
        console.log(validarClaims);

        if (!validarClaims) {
            return
        }
        
        const indexAvance = avance.findIndex(element => element.index === index);
        setDisabled(false)


        if (indexAvance >= 0) {
            setAvance((avance) => {
                return avance.map((element) => {  
                    // console.log(element);
                    if (element.index === index) {
                        // console.log('finded');
                        if (element[name]!=null) {
                            return { ...element, [name]: !element[name] };
                        } else {
                            return { ...element, [name]: !value };
                        }

                    } else {
                        return element
                    }
                })
            })
            // console.log('Si encontrado:');
            // console.log(avance);
        } else {
            setAvance([...avance ,{ index: index, [name]:!value}]);
            // console.log('No encontrado:');
            // console.log(avance);
        }
    }


    const handleGuardarAvance = () => {
        console.log(avance);
        setDisabled(true)
        let changeAvance = {
            docId: idDoc,
            avanCambios: avance
        }

        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            const token = 'Token ' + idToken
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
                headers: { 'authorization': token }
            });
            
            instance.post('/user/changeAvanFin', changeAvance)
            .then(function (response) {
                console.log(response)
                setDisabled(false)
            })
            .catch(function (error) {
                console.log(error);
                setDisabled(false)
            });
      
        }).catch(function (error) {
            setDisabled(false);
            return error;
        });
    }

    useEffect(() => {
        const getDoc = firebase.firestore().collection('proyectos').doc(idDoc).collection('cat').doc('financiero')
            .onSnapshot(snapshot => {
                if (snapshot.exists) {
                    setDocFinanciero({ exists: true, data: snapshot.data() });
                    setAvance([]);
                    if (snapshot.data().hasOwnProperty('avance')) {
                        setNumAvances(snapshot.data().avance.length + 1);
                    } else {
                        setNumAvances(1);
                    }
                } else {
                    setDocFinanciero({exists: false, data: {}});
                    setNumAvances(1);
                }
            })
        return () => {
          getDoc();
        }
      }, [firebase])

    return (
        <>

        {(docFinanciero.data.hasOwnProperty('avance'))
            ? docFinanciero.data.avance.map((doc, index) => {
                let date;
                totalAvance += Number(calcAvance(doc.pagado, Number(doc.importe) * 1.16))
                
                if (!mostrarEstimacionInfo.value || mostrarEstimacionInfo.index === index) {
                    
                    return (
    
                        <TableRow size='medium'>    
                            <TableCell align='center' className={classes.tableCellBorder}>{index + 1}</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                {
                                    (!isNaN(parseInt(doc.periodoInicio)) && doc.periodoInicio)
                                    && dateFormat(date = new Date(parseInt(doc.periodoInicio) * 1), "dd/mm/yy")
                                }
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                {
                                    (!isNaN(parseInt(doc.periodoFinal)) && doc.periodoFinal)
                                    && dateFormat(date = new Date(parseInt(doc.periodoFinal) * 1), "dd/mm/yy")
                                }
                            </TableCell>
                            <TableCell align='right' className={classes.tableCellBorder}>
                                {'' + Intl.NumberFormat('en-US', { style: "currency", currency: "USD", }).format(Number(doc.importe))}
                            </TableCell>
                            <TableCell align='right' className={classes.tableCellBorder}>
                                {'' + Intl.NumberFormat('en-US', { style: "currency", currency: "USD", }).format(Number(doc.importe) * 0.16)}
                            </TableCell>
                            <TableCell align='right' className={classes.tableCellBorder}>
                                {'' + Intl.NumberFormat('en-US', { style: "currency", currency: "USD", }).format(Number(doc.importe) * 1.16)}
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                {
                                    (!isNaN(parseInt(doc.fechaEntrega)) && doc.fechaEntrega)
                                    && dateFormat(date = new Date(parseInt(doc.fechaEntrega) * 1), "dd/mm/yy")
                                }
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                <Checkbox
                                    checked={doc.valResidente}
                                    />
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                <Checkbox
                                    checked={ getAvance(index, 'valTecnica',  doc.valTecnica) }
                                    onClick={(e) => handleAvanceChange(index, 'valTecnica', doc.valTecnica)}
                                    />
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                <Checkbox
                                    checked={ getAvance(index, 'valAdmin',  doc.valAdmin) }
                                    onClick={(e) => handleAvanceChange(index, 'valAdmin', doc.valAdmin)}
                                    />
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                <Checkbox
                                    checked={ getAvance(index, 'valGeneral',  doc.valGeneral) }
                                    onClick={(e) => handleAvanceChange(index, 'valGeneral', doc.valGeneral)}
                                    />
                            </TableCell>
                
                            <TableCell align='center' className={classes.tableCellBorder}>
                                {doc.pagado ?
                                    <Checkbox
                                    checked={ getAvance(index, 'pagado',  doc.pagado) }
                                    />
                                    :
                                    <Checkbox
                                    checked={ getAvance(index, 'pagado',  doc.pagado) }
                                    onClick={(e) => handleAvanceChange(index, 'pagado', doc.pagado)}
                                    />
                                }
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                {
                                    (!isNaN(parseInt(doc.fechaPago)) && doc.fechaPago)
                                    && dateFormat(date = new Date(parseInt(doc.fechaPago) * 1), "dd/mm/yy")
                                }
                            </TableCell>
                            <TableCell align='right' className={classes.tableCellBorder}>
                                {calcAvance(doc.pagado, Number(doc.importe) * 1.16)+'%'}
                            </TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>
                                
                                {!mostrarEstimacionInfo.value
                                    ? 
                                        <VisibilityOutlined
                                            onClick={e => handleShowConcept(e, index, doc)}
                                        />
                                    :
                                        <VisibilityOffOutlined
                                        onClick={e => handleShowConcept(e, index, doc)}
                                        />
                                
                                }
                            </TableCell>
                        </TableRow>
                   ) 
                }

            }) :
            <TableRow size='medium'>    
                <TableCell align='center' colSpan={13} className={classes.tableCellBorder}>Sin datos</TableCell>
            </TableRow>}
            
            
            {!mostrarEstimacionInfo &&
            
                <TableRow size='medium'>    
                    <TableCell align='center' colSpan={12} className={classes.tableCellBorder}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={disabled}
                            onClick={handleGuardarAvance}
                        >Guardar</Button>
                    </TableCell>
                    <TableCell align='center' colSpan={1} className={classes.tableCellBorderColor}>
                        Total:
                    </TableCell>
                    <TableCell align='center' colSpan={1} className={classes.tableCellBorder}>
                        {totalAvance + '%'}
                    </TableCell>
                </TableRow>
            }
        </>
    )
}
