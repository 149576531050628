
import firebase from 'firebase'
import 'firebase/auth'



export async function getCurrentUser(){
    const currentUser = firebase.auth().currentUser;
    return currentUser;
}
/*
export function getUserId(){
    var user = firebase.auth().currentUser;
    return user.uid;
}*/

export const getUserId = async () => {
    const result = await getCurrentUser();
    return result.uid;
  }

export const getUserProfilePicByUsername = (username) => {
  firebase.storage().ref().child('FotosPerfil/'+ username +'.jpg').getDownloadURL().then(function(url){
    console.log(url)
    return url;
  }).catch(function(error){
    return '';
  });
}


export const getUserToken = () =>{
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        return idToken;
      }).catch(function(error) {
        return error;
      });
}

