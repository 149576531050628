import React from 'react'
import { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import Compressor from 'compressorjs';


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//Teble
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//Dialog

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import firebase from 'firebase';
import axios from 'axios';



import {getUserProfilePicByUsername} from '../../utils/misFunciones.js';


import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles((theme) => ({
    paperPrincipal: {
        margin: 'auto',
        padding: '20px',
        maxWidth: '1200px',
        textAlign: 'center',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
    },
    btnEliminar: {
        backgroundColor: '#EE3A34',
        color: '#000',
        margin: 2,
        width: '90px',
        fontStyle: 'bold',
        '&:hover': {
            backgroundColor: '#C43131',
        },
    },
    btnEditar: {
        backgroundColor: '#F39C12',
        color: '#000',
        margin: 2,
        width: '90px',
        fontStyle: 'bold',
        '&:hover': {
            backgroundColor: '#D68910',
        },
    },
    btnAgregar: {
        position: 'relative'
    },
    progress: {
        position: 'absolute'
    },
    nUserInput: {
        marginTop: 15,
    },
    inputIgm: {
        display: "none",
        margin: 'auto',
    },
    avatarImg: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListaUsuarios() {

    const classes = useStyles();

    //Alerta
    const [OpenAlerta, setOpenAlerta] = React.useState(false);
    const [loading, setloading] = useState(false);
    const [UsersData, setUsersData] = useState();
    const [newuserRender, setnewuserRender] = useState(false);

    const editUsuario = {
        username: '',
        password: '',
        tipo: 1,
        nombre: '',
        cargo: '',
    }
    const [EditUser, setEditUser] = useState(editUsuario);

    const handleEditUserchange = (event, id) => {
        var text = event.target.value;
        EditUser[id] = text;
        console.log(EditUser)
    }



    const [TipoUser, setTipoUser] = React.useState(1)
    const handleChange = (event) => {
        var tipo = event.target.value;
        EditUser['tipo'] = tipo;
        setTipoUser(tipo);
        console.log(EditUser);
    };





    //Abrir dialogo para confirmar eliminacion del usuario
    const [openEliminar, setOpenEliminar] = React.useState(false);
    //Guardar usuario que se desea eliminar
    const [UsuarioEliminar, setUsuarioEliminar] = React.useState(null)
    //Funcion para abrir el dialog
    const handleClickOpenEliminar = (user) => {
        setUsuarioEliminar(user);
        setOpenEliminar(true);
    };
    //Funcion para cerrar el dialogo
    const handleCloseEliminar = () => {
        setOpenEliminar(false);
    };


    const handleConfirmEliminar = (usernameDelete) => {
        setloading(true)
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            const token = 'Token ' + idToken
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
                headers: { 'authorization': token }
            });

            const userId = {
                username: usernameDelete
            }

            instance.post('/admin/eliminarUsuario', userId)
                .then(function (response) {
                    console.log(response)
                    setOpenEliminar(false);
                    setloading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setOpenEliminar(false);
                    setloading(false);
                });

        }).catch(function (error) {
            return error;
        });
    }


    //Abrir dialogo para agregar usuario
    const [openAgregarU, setOpenAgregarU] = React.useState(false);
    //Funcion para abrir el dialog
    const handleClickOpenAgregarU = () => {
        setOpenAgregarU(true);
    };
    //Funcion para cerrar el dialogo
    const handleCloseAgregarU = () => {
        setOpenAgregarU(false);
        setEditUser(editUsuario);
        setnewuserRender(false);
        //document.getElementById('nuser-username').value = null;
    };
    //Funcion para agregar el usuario nuevo
    const handleAgregarAgregarU = () => {
        setloading(true)
        // subirNewUserImg();
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {

            const token = 'Token ' + idToken
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
                headers: { 'authorization': token }
            });

            instance.post('/admin/nuevoUsuario', EditUser)
                .then(function (response) {
                    console.log(response);
                    setEditUser(editUsuario);
                    setnewuserRender(false);
                    setOpenAgregarU(false);
                    setloading(false);
                    setTipoUser(1);
                })
                .catch(function (error) {
                    console.log(error);
                    setEditUser(editUsuario);
                    setOpenAgregarU(false);
                    setloading(false);
                    setTipoUser(1);
                });

        }).catch(function (error) {
            return error;
        });
    };



    useEffect(() => {
        setTimeout(function () {
            setOpenAlerta(false);
        }, 5000);
    }, [OpenAlerta]);


    useEffect(() => {
        setnewuserRender(true);
        setTipoUser(1);
    }, [newuserRender]);

    useEffect(() => {

        const getUsers = firebase.firestore().collection('userData').onSnapshot((querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({ username: doc.id, ...doc.data() }));
            setUsersData(data);
        })

        return () => {
            getUsers()
        }
    }, [firebase])

    useEffect(() => {
        //SaveFotosUsuarios();
    }, [UsersData]);
    


    // Agregar imagenes de usuario a la variable para mostrarlo en la pagina
    // Guardar UsersPics
    const [UsuarioFotoURL, setUsuarioFotoURL] = React.useState({});
    const [RenderUsers, setRenderUsers] = useState(false);
    useEffect(() => {
    }, [UsuarioFotoURL]);


    useEffect(() => {
        setRenderUsers(true);
    }, [RenderUsers]);
    const SaveFotosUsuarios = () =>{
        
        if(UsersData){

            UsersData.map((doc) => {
            // UsuarioFotoURL[doc.username] = getUserProfilePicByUsername(doc.username); 
            firebase.storage().ref().child('FotosPerfil/'+ doc.username +'.jpg').getDownloadURL().then(function(url){
                    UsuarioFotoURL[doc.username] = url;
                    setRenderUsers(false);
                }).catch(function(error){

                    UsuarioFotoURL[doc.username] = '';

                });
            });
        }
        
        
        console.log(UsuarioFotoURL);
    }




    //Convertir imagen en base64
    function subirNewUserImg() {
        var file = document.getElementById('input-newUser-edit').files[0];
        //var file = event.target.files[0];
        if(!file){
            return;
        }

        new Compressor(file, {
            strict: true,
            quality: 0.9,
            maxWidth: 600,
            mimeType: 'image/jpeg',

            success(result) {
              console.log(result);
              var storageRef = firebase.storage().ref().child('FotosPerfil/' + EditUser.username + '.jpg');
              storageRef.put(result).then(function(snapshot) {
                console.log('Se subió la foto!');
              });
            },
            error(err) {
              console.log(err.message);
            },
        });
    }

    function handleChangeImg(event){
        var file = event.target.files[0];
        var preview = document.getElementById('nuser-img-preview');
        var reader = new FileReader();
        reader.readAsDataURL(file);
        preview.src = "";
        reader.onload = function (event) {
            // El texto del archivo se mostrará por consola aquí
            preview.src = reader.result;
        };
    }








    return (
        <div>

            <Snackbar
                open={OpenAlerta}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert severity="success">
                    Usuario eliminado correctamente
                </MuiAlert>
            </Snackbar>

            <Dialog
                open={openEliminar}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseEliminar}
            >
                <DialogTitle id="seguroEliminar">{"Seguro que desea eliminar al usuario?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Si continua los datos del usuario {UsuarioEliminar} seran borrados.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEliminar} color="primary">
                        Cancelar
                </Button>
                    <Button onClick={(event) => handleConfirmEliminar(UsuarioEliminar)} color="primary" disable={loading}>
                        Eliminar
                    {loading && (
                            <CircularProgress size={30} className={classes.progress}></CircularProgress>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openAgregarU}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAgregarU}
            >
                <DialogTitle id="seguroEliminar">{"Agregar Usuario"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="nuser-dialog-content">
                    </DialogContentText>

                    {
                        newuserRender && (() => {
                            return (
                                <div>
                                    
                                        
                                    {/* <input accept="image/*" className={classes.inputIgm} id="input-newUser-edit" type="file" onChange={(event) => handleChangeImg(event)}/>
                                    <label htmlFor="input-newUser-edit">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                        <Avatar className={classes.avatarImg} ><img id='nuser-img-preview' src="" height="200" alt="Cargando..."/></Avatar>
                                        </IconButton>
                                    </label> */}
     


                                    <TextField id="nuser-username" className={classes.nUserInput} label="Usuario" defaultValue='' variant="outlined" fullWidth onChange={(event) => handleEditUserchange(event, 'username')} />
                                    <TextField id="nuser-password" className={classes.nUserInput} type='password' label="Contraseña" variant="outlined" fullWidth onChange={(event) => handleEditUserchange(event, 'password')} />
                                    <TextField id="nuser-nombre" className={classes.nUserInput} label="Nombre" variant="outlined" fullWidth onChange={(event) => handleEditUserchange(event, 'nombre')} />
                                    <TextField id="nuser-cargo" className={classes.nUserInput} label="Cargo" variant="outlined" fullWidth onChange={(event) => handleEditUserchange(event, 'cargo')} />
                                    <FormControl variant="outlined" className={classes.nUserInput} fullWidth>
                                        <InputLabel id="nuser-Tipo-Input-label">Tipo</InputLabel>
                                        <Select
                                            labelId="nuserTipo-labelID"
                                            id="nuserTipo"
                                            value={TipoUser}
                                            onChange={(event) => handleChange(event)}
                                            label="Tipo"
                                            defaultValue={1}
                                        >
                                            {/* //? Agregar nuevos usuarios (  )  */}
                                            <MenuItem value={1}>Residente</MenuItem>
                                            <MenuItem value={2}>Director General</MenuItem>
                                            <MenuItem value={5}>* Director Administrativo</MenuItem>
                                            <MenuItem value={6}>* Director Construcción</MenuItem>
                                            <MenuItem value={7}>* Director Técnico</MenuItem>
                                            <MenuItem value={8}>* Pagos / Egresos</MenuItem>
                                            <MenuItem value={4}>Supervisor</MenuItem>
                                            <MenuItem value={3}>Administrador</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        })()

                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAgregarU} color="primary">
                        Cancelar
                </Button>
                    <Button onClick={handleAgregarAgregarU} color="primary" disabled={loading}>
                        Agregar
                    {loading && (
                            <CircularProgress size={30} className={classes.progress}></CircularProgress>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>


            <Paper className={classes.paperPrincipal}>
                <div className={classes.title}>
                    <h1>Usuarios</h1>
                </div>

                <div>
                    <Button variant="contained" color="primary" onClick={handleClickOpenAgregarU}>Agregar Usuario</Button>
                </div>

                <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        USUARIO
                                    </TableCell>
                                    <TableCell>
                                        NOMBRE
                                    </TableCell>
                                    <TableCell>
                                        TIPO
                                    </TableCell>
                                    <TableCell>
                                        CARGO
                                    </TableCell>
                                    <TableCell>
                                        Acciones
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                {
                                    RenderUsers && UsersData && UsersData.map(doc => {
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    <Chip
                                                        avatar={<Avatar src ={UsuarioFotoURL[doc.username]} >{doc.username[0].toUpperCase()}</Avatar>}
                                                        label={doc.username}
                                                        clickable
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {doc.nombre}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        (doc.tipo === 'usuario') ? 'Residente' :
                                                        (doc.tipo === 'supervisor') ? 'Supervisor' :
                                                        (doc.tipo === 'director') ? 'Director' :
                                                        (doc.tipo === 'admin') ? 'Administrador' : doc.tipo
                                                    
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {doc.cargo}
                                                </TableCell>
                                                <TableCell>
                                                    {/*<Button variant="contained" color="primary" className={classes.btnEditar} size='small'>Editar</Button>*/}
                                                    <Button variant="contained" color='primary' className={classes.btnEliminar} size='small' onClick={(event) => handleClickOpenEliminar(doc.username)}>Eliminar</Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    )
}
