//M-UI imports

import React, { useEffect } from 'react';
import {useRef } from 'react'
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


//import Fade from '@material-ui/core/Fade';
//M-UI Icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';


import firebase from 'firebase/app';
import 'firebase/storage';




//Ventanas
import Perfil from './Perfil'
import AgregarProy from './director/AgregarProy'
import ListaAllProyectos from './admin/ListaAllProyectos';


import AvatarUser from './AvatarUser';
import Chip from '@material-ui/core/Chip';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    },
  menuButton: {
    marginRight: theme.spacing(0),
    height: theme.spacing(0),
    width: theme.spacing(0),
    margin: 'auto',
    marginLeft: 0
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  
  drawerIcon: {
    
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    margin: '0px',
    padding: '0px'
    ,
    padding: theme.spacing(1),
  },
  barMenu: {
    marginTop: 45,
  },
  }));




  export default function Director( props ) {


    const theme = useTheme();
    const [component, setComponet] = React.useState(<h1>Cargando...</h1>);

    
    useEffect(() => {
      setComponet(<ListaAllProyectos setComponet={setComponet} director={true}></ListaAllProyectos>)
    }, [])
    //Menu lateral izquierdo
    const [open, setOpen] = React.useState(false);
    // Avatar Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    
    //IMAGEN DEL PERFIL DEL USUARIO
    /*
    const [perfilimg, setPerfilImg] = React.useState();
    firebase.storage().ref().child('FotosPerfil/enriquechac.jpg').getDownloadURL().then(function(url){
      setPerfilImg(url)
    }).catch(function(error){

    });
    */
    //console.log(perfilimg);
    
    

    //Menu lateral
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    //Avatar menu
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };


    //Metodo para el cerrar la sesion del ususario
    const handleCerrarSesion = () => {
      setAnchorEl(null);
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    };

    const handlePerfil = () => {
      setAnchorEl(null);
      setComponet(<Perfil username={firebase.auth().currentUser.uid}></Perfil>);
    }

    const handleMenuClick = (id) => {
        console.log(id);
        switch(id){
            case 1:
              setComponet(<ListaAllProyectos setComponet={setComponet} director={true}></ListaAllProyectos>);
                break;
            case 2:
                //setComponet(<AgregarProy></AgregarProy>);
            default:
                break;
        }
    }


    const classes = useStyles();
        return (
            <div className={classes.root}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <Box display='flex' flexGrow={1}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <h3 className='h2bar'>AvanProy</h3>
                            {/* <Button color='inherit'>Inicio</Button> */}
                        </Box>
                        <Chip
                          size='medium'
                          avatar={<AvatarUser username={firebase.auth().currentUser.uid[0].toUpperCase()}></AvatarUser>}
                          label={firebase.auth().currentUser.uid}
                          clickable
                          className={classes.menuChip}
                          color="primary"
                          onClick={(event) => handleMenu(event)}
                          variant="default"
                        />
                        <Menu
                          id="menu-appbar"
                          className={classes.barMenu}
                          anchorEl={anchorEl}
                          getContentAnchorEl={null}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                  
                        >
                          <MenuItem onClick={handlePerfil}><PersonOutlineIcon />  Perfil</MenuItem>
                          <MenuItem onClick={handleCerrarSesion}><ExitToAppIcon />  Cerrar Sesión</MenuItem>
                        </Menu>                  
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem button onClick={(event) => handleMenuClick(1, event)}>
                            <ListItemIcon> <DescriptionIcon /> </ListItemIcon>
                            <ListItemText primary='Proyectos'/>
                        </ListItem>
                        {/* <ListItem button onClick={(event) => handleMenuClick(2, event)}>
                            <ListItemIcon> <NoteAddIcon /> </ListItemIcon>
                            <ListItemText primary='Agregar Proyecto'/>
                            </ListItem> */}
                    </List>
                </Drawer>



                <main className={classes.content}>
                    {component}
                </main>
            </div>
            
        )
    }
