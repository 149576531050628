import React, { useEffect, useState } from 'react'
import { Button, InputAdornment, TableCell, TableRow, TextField } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import dateFormat from 'dateformat'

export const TableRowConcepto = ({ docProy, index, classes }) => {
    
    const [subConceptos, setSubConceptos] = useState([])

    const [renderSub, setRenderSub] = useState(false)
    const [renderMonto, setRenderMonto] = useState(false)

    useEffect(() => {
        setRenderSub(true)
    }, [renderSub])

    
    useEffect(() => {
        setRenderMonto(true)
    }, [renderMonto])


    // Funcion para agregar un nuevo subconcepto
    const handleAgregarSubConcepto = () => {
        docProy.agregarSubConcepto(index);
        setSubConceptos(docProy.detalle[`cvacata${index + 1}`]);
        // Coloco el rendersub en false para cargar de nuevo los elementos.
        setRenderSub(false)
    }
    // Funcion para agregar un nuevo subconcepto
    const handleQuitarSubConcepto = () => {
        docProy.quitarSubConcepto(index);
        setSubConceptos(docProy.detalle[`cvacata${index + 1}`]);
        // Coloco el rendersub en false para cargar de nuevo los elementos.
        setRenderSub(false)
    }

    const handleCambioSubConcepto = (subConceptoId, e) => {
        docProy.setSubConcepto(index + 1, subConceptoId, e)
        if (e.target.name === 'punitario' || e.target.name === 'cantidad') {
            setRenderMonto(false)
        }
        console.log(subConceptos);
        
    }


    return (
        <>
            <TableRow className={classes.tableConcepto}>
                <TableCell  className={classes.tableCell} colSpan={1}>
                    <TextField
                        key='asd'
                        label={(index + 1)+'.- Clave'}
                        style={{ margin: 8 }}
                        variant='standard'
                        size='small'
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name='clave'
                        onChange={(e)=>{docProy.setConcepto(index, e)}}
                        ></TextField>
                </TableCell>
                <TableCell  className={classes.tableCell} colSpan={6}>
                    <TextField
                        key='asd'
                        label={(index + 1)+'.- Descripción'}
                        style={{ margin: 8 }}
                        variant='standard'
                        size='small'
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name='descripcion'
                        multiline
                        onChange={(e)=>{docProy.setConcepto(index, e)}}
                        ></TextField>
                </TableCell>
                <TableCell align='right' className={classes.tableCellMonto}>
                    {/* <h3>{"$" + RenderConceptoValue ? concepto.precio : 0}</h3> */}
                    <TextField  
                        className={classes.tableInputMonto}
                        key={'.- Monto Total'}
                        label={(index + 1)+'.- Monto sin IVA'}
                        style={{ margin: 8 }}
                        variant='standard'
                        type='text'
                        inputProps={{
                            style: { textAlign: "right",}
                        }} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }} 
                        size='small'
                        fullWidth
                        value={
                            (renderMonto && docProy.resumen.desccatres[index].precio) && 
                            Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(docProy.resumen.desccatres[index].precio)
                        }                        
                        ></TextField>
                </TableCell>
            </TableRow>

            {subConceptos.map((subconcepto, index) => (
                <TableRow className={classes.table} key={`SUBCONCEPTO-ID-${index}`}>


                    <TableCell className={classes.tableCell}>
                        <TextField
                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle}
                            label={'Clave'}
                            style={{ margin: 8, }}
                            defaultValue = {subconcepto.clave}
                            variant='standard'
                            size='small'
                            fullWidth
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            name='clave'
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>


                    <TableCell className={classes.tableCell}>
                        <TextField
                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle}
                            label={subconcepto.cvacata+'.'+subconcepto.cvadetalle + '.- Descripción'}
                            style={{ margin: 8 }}
                            defaultValue = {subconcepto.descripcion}
                            variant='standard'
                            size='small'
                            fullWidth
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            name='descripcion'
                            multiline
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>


                    <TableCell className={classes.tableCell}>
                        <TextField
                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle + 'unidad'}
                            label={'Unidad'}
                            style={{ margin: 8 }}
                            defaultValue = {subconcepto.descripcion}
                            variant='standard'
                            size='small'
                            inputProps={{
                                style: { textAlign: "right",}
                            }} 
                            fullWidth
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            name='unidad'
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>


                    <TableCell align='right' className={classes.tableCellMonto}>
                        <TextField  
                            className={classes.tableInputMonto}
                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle+'cantidad'}
                            label={'Cantidad'}
                            style={{ margin: 8 }}
                            defaultValue = {subconcepto.precio}
                            variant='standard'
                            type='number'
                            inputProps={{
                                style: { textAlign: "right",}
                            }} 
                            size='small'
                            fullWidth
                            name='cantidad'
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>


                    <TableCell align='right' className={classes.tableCellMonto}>
                        <TextField  
                            className={classes.tableInputMonto}
                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle+'punitario'}
                            label={'Punitario'}
                            style={{ margin: 8 }}
                            defaultValue = {subconcepto.precio}
                            variant='standard'
                            type='number'
                            inputProps={{
                                style: { textAlign: "right",}
                            }} 
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }} 
                            size='small'
                            fullWidth
                            name='punitario'
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>


                    <TableCell align='right' className={classes.tableCellMonto}>
                        <TextField  
                            className={classes.tableInputMonto}
                            key={subconcepto.cvacata+'.'+subconcepto.cvadetalle+'monto'}
                            label={'Monto'}
                            style={{ margin: 8 }}
                            defaultValue = {subconcepto.precio}
                            variant='standard'
                            type='text'
                            inputProps={{
                                style: { textAlign: "right",}
                            }} 
                            size='small'
                            fullWidth
                            value={
                                renderMonto && 
                                Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(subConceptos[index].monto)
                            }
                            name='monto'
                        ></TextField>
                    </TableCell>



                    <TableCell align='right' className={classes.tableCellMonto}>
                        <TextField
                            label='Fecha de Inicio'
                            style={{ margin: 8 }}
                            variant='outlined'
                            fullWidth
                            type='date'
                            name='finicio'
                            InputLabelProps={{
                                shrink: true,
                              }}
                            defaultValue={dateFormat(Date.now(), "yyyy-mm-dd")}
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>


                    <TableCell align='right' className={classes.tableCellMonto}>
                        <TextField
                            label='Fecha de Termino'
                            style={{ margin: 8 }}
                            variant='outlined'
                            fullWidth
                            type='date'
                            name = 'ftermino'
                            defaultValue={dateFormat(Date.now(), "yyyy-mm-dd")}
                            InputLabelProps={{
                                shrink: true,
                              }}
                            onChange={(e) => { handleCambioSubConcepto(index, e) }}
                        ></TextField>
                    </TableCell>
                </TableRow>
            ))}

            <TableRow className={classes.tableBoton}>
                <TableCell key={index + 'concepto'} colSpan={6} ></TableCell>
                <TableCell key={index + 'boton'}align='right' colSpan={1} className={classes.tableCellMonto} >
                    <Button
                        variant="contained"
                        key={index + 'botonAdd'}
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAgregarSubConcepto}
                    >Agregar Subcapitulo</Button>
                </TableCell>
                <TableCell key={index + 'botonR'}align='right' colSpan={1} className={classes.tableCellMonto} >
                    <Button
                        variant="contained"
                        key={index + 'botonRemove'}
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<RemoveCircleOutlineIcon />}
                        onClick={handleQuitarSubConcepto}
                    >Quitar Subcapitulo</Button>
                </TableCell>
            </TableRow>
        </>
    )
}
