import React from 'react'
import { Button, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'

export const TablaConceptosTerminados = ({docFinanciero, docCat, useStyles}) => {

    console.log(docFinanciero);
    
    const styles = {
        container: {
            display: 'flex',
            margin: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        paper: {
            display: 'flex',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '80%',
        },
    }



    const getFinanciero = (cvacata, cvadetalle) => {
        let suma = 0;
        docFinanciero?.data?.avance?.map(estimacion => {

            suma += (estimacion?.conceptos && 
            typeof estimacion?.conceptos[`cvacata${cvacata}cvadetalle${cvadetalle}`] != 'undefined') ? Number(estimacion?.conceptos[`cvacata${cvacata}cvadetalle${cvadetalle}`]?.cantidad) : 0
            
        })
        return suma
    }
    
    const classes = useStyles();

    let sumaMontoConceptosCerrados = 0;

    return (
      
        <>
            <br />
            <div style={styles.container}>
            <Paper style={styles.paper} sx={{ overflow: 'hidden' }}>
            <TableContainer>
                <Table className={classes.table} size='small'>
                    <TableHead>
                        <TableRow size='medium'>
                            <TableCell align='center' className={classes.tableCellBorder}>CLAVE</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>DESCRIPCION</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>UNIDAD</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD CANCELADA</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>PRECIO UNITARIO</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>MONTO RECUPERADO</TableCell>
                            {/* <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD ESTIMA</TableCell>
                            <TableCell align='center' className={classes.tableCellBorder}>MONTO ESTIMA</TableCell> */}
                        </TableRow>
                    </TableHead>

                    {
                    docCat['resumen']?.desccatres?.map( (concepto) => {
                        
                        let mostrarTitulo = -1;

                        return( 
                            <TableBody>

                                {
                                    // docCat['detalle']['cvacata' + concepto.cvacata]?.map(subConcepto => {
                                    //     if (docFinanciero?.data?.concluido?.hasOwnProperty(`cvacata${subConcepto.cvacata}cvadetalle${subConcepto.cvadetalle}`)) {
                                    
                                    docCat['detalle']['cvacata' + concepto.cvacata].map(subConcepto => {
                                        if (docFinanciero?.data?.concluido?.hasOwnProperty(`cvacata${subConcepto.cvacata}cvadetalle${subConcepto.cvadetalle}`) && docFinanciero?.data?.concluido[`cvacata${subConcepto.cvacata}cvadetalle${subConcepto.cvadetalle}`]) {
                                            
                                            
                                            mostrarTitulo++;
                                            
                                            let cantCancelada = Number(subConcepto.cantidad) - Number(getFinanciero(subConcepto.cvacata, subConcepto.cvadetalle));

                                            sumaMontoConceptosCerrados += Number(cantCancelada) * Number(subConcepto.punitario)

                                            return (
                                                <>
                                                    {
                                                        (mostrarTitulo === 0) &&    
                                                        <TableRow className={classes.tableHead}>
                                                            <TableCell colSpan={1} className={classes.tableCell}>{(concepto.clave + '').toUpperCase()}</TableCell>
                                                            <TableCell colSpan={5} className={classes.tableCell}>{concepto.cvacata}.- {(concepto.descripcion+ '').toUpperCase()}</TableCell>
                                                        </TableRow>
                                                    }
                                                    <TableRow className={classes.tableCellBorder}>
                                                        <TableCell className={classes.tableCellBorder}>{(''+subConcepto.clave).toUpperCase()}</TableCell>
                                                        <TableCell className={classes.tableCellBorder}>{subConcepto.cvacata}.{subConcepto.cvadetalle} {subConcepto.descripcion}</TableCell>
                                                        <TableCell className={classes.tableCellBorder}>{(''+subConcepto.unidad).toUpperCase()}</TableCell>
                                                        <TableCell align='center' className={classes.tableCellBorder}>{cantCancelada}</TableCell>
                                                        {/* <TableCell align='center' className={classes.tableCellBorder}>{
                                                            getFinanciero(subConcepto.cvacata, subConcepto.cvadetalle)
                                                        }</TableCell> */}
                                                        <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(subConcepto.punitario))}</TableCell>
                                                        <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(cantCancelada) * Number(subConcepto.punitario))}</TableCell>
                                                        {/* <TableCell align='center'
                                                            className={classes.tableCellBorderAvance}>
                                                        </TableCell> */}
                                                        {/* <TableCell align='right' className={classes.tableCellBorder}>{
                                                            '' + Intl.NumberFormat('en-US', { style: "currency", currency: "USD", }).format(Number(docFinanciero.data['cvacata' + subConcepto.cvacata + 'cvadetalle' + subConcepto.cvadetalle].montoEstima))
                                                        }</TableCell> */}
                                                    </TableRow>
                                                </>
                                                                 
                                            )
                                        }
                                    })
                                }
                            </TableBody>
                        )
                    })
                }
                
                </Table>
                </TableContainer>
                <br />
                    <div style={{width:'100%', display:'flex', justifyContent:'right'}}>
                        <TableContainer style={{width:'300px'}}>
                            <Table>
                                <TableBody>
                                    <TableRow size='medium'>
                                        <TableCell align='center' className={classes.tableHead}>SUMA</TableCell>
                                        <TableCell align='center' className={classes.tableCellBorder}>
                                        {''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(sumaMontoConceptosCerrados)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow size='medium'>
                                        <TableCell align='center' className={classes.tableHead}>IVA</TableCell>
                                        <TableCell align='center' className={classes.tableCellBorder}>
                                        
                                        {''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(sumaMontoConceptosCerrados * 0.16)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow size='medium'>
                                        <TableCell align='center' className={classes.tableHead}>TOTAL</TableCell>
                                        <TableCell align='center' className={classes.tableCellBorder}>
                                        {''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(sumaMontoConceptosCerrados * 1.16)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
            </Paper>
            </div>
            
        </>
    )
}

