import { Button, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import dateFormat from 'dateformat'
import React, { useState, useEffect } from 'react';
import firebase from 'firebase'
import axios from 'axios';

const styles = {
    container: {
        display: 'flex',
        margin: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    paper: {
        display: 'flex',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
    },
    divInputs: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        
    },
    inputDates: {
        minWidth: '170px',
        width: '170px',
        margin: 4,
        flexGrow: 1
    },
    input: {
        minWidth: '200px',
        width: '20px',
        flexGrow: 1,
        margin: 4
    },
    button: {
        margin: 10
    }
}


export const AgregarAvance = ({idDoc, numAvances, importContratoIva, docCat, useStyles, setshowAddEstimacion, docFinanciero}) => {

    const [disableButton, setDisableButton] = useState(true)

    const classes = useStyles();

    const [nAvance, setnAvance] = useState({
        docId: idDoc,
        estimacion: numAvances,
        periodoInicio: Date.now(),
        periodoFinal: Date.now(),
        importe: '',
        fechaEntrega: Date.now(),
        residente: firebase.auth().currentUser.uid,
    });

    const [importeValue, setImporteValue] = useState('')
    const [estimacionConceptos, setEstimacionConceptos] = useState({});


    useEffect(() => {
        console.log(docFinanciero)
        let importe = 0
        Object.entries(estimacionConceptos).forEach(([key, value]) => {
            importe += value.montoEstima
        })
        setImporteValue(importe)
        setnAvance((nAvance) => {
            return {
                ...nAvance,
                conceptos: estimacionConceptos
            }
        })
    }, [estimacionConceptos])
    
    


    const handleEstimacionConcepto = (element, cantidadU, event) => {
        const { cvacata, cvadetalle, punitario, cantidad } = element;
        const restante = cantidad - cantidadU;
        const valor = (event.target.value < 0)
            ? ''
            : (event.target.value > Number(restante))
                ? Number(restante)
                : Number(event.target.value);
        
                setEstimacionConceptos(estimacionConceptos => {
                    return {
                        ...estimacionConceptos,
                        ['cvacata' + cvacata + 'cvadetalle' + cvadetalle]: {
                            cantidad: valor,
                            montoEstima: valor * punitario,
                        }
                    }
                })
        
        if (valor === 0) {
            delete estimacionConceptos['cvacata' + cvacata + 'cvadetalle' + cvadetalle];
        }
    }


    const handleInputChange = (e) => {
        console.log(Date.now());
        
        let name = e.target.name;
        let value = e.target.value;
        nAvance['estimacion'] = numAvances;
        setDisableButton(false)
        if (name === 'importe') {
            if (Number(value) > 0) {
                if (Number(value) <= Number(importContratoIva)) {
                    nAvance[name] = Number(value);
                    setImporteValue(Number(value))
                } else {
                    nAvance[name] = Number(importContratoIva);
                    setImporteValue(Number(importContratoIva))
                }
            } else {
                setDisableButton(true)
                nAvance[name] = 0;
                setImporteValue('')
            }
        } else {
            let Fecha = new Date(value);
            Fecha.setDate(Fecha.getDate() + 1)
            let FechaTimeStamp = Fecha.getTime();
            nAvance[name] = FechaTimeStamp;
        }
        console.log(nAvance);
    }


    const handleAgregarAvance = () => {
        setDisableButton(true)
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            const token = 'Token ' + idToken
            const instance = axios.create({
                baseURL: 'https://us-central1-avanproy-test.cloudfunctions.net/api/',
                headers: { 'authorization': token }
            });
            
            
            instance.post('/user/nAvanFin', {
                ...nAvance,
                importe: importeValue
            })
            .then(function (response) {
                setshowAddEstimacion(false)
            })
            .catch(function (error) {
                setDisableButton(false)
            });
            
        }).catch(function (error) {
            setDisableButton(false)
            return error;
        });
    }

    const getFinanciero = (cvacata, cvadetalle) => {
        let suma = 0;
        docFinanciero?.data?.avance?.map(estimacion => {

            suma += (estimacion?.conceptos && 
            typeof estimacion?.conceptos[`cvacata${cvacata}cvadetalle${cvadetalle}`] != 'undefined') ? Number(estimacion?.conceptos[`cvacata${cvacata}cvadetalle${cvadetalle}`]?.cantidad) : 0
            
        })
        return suma
    }



    return (
        <div style={styles.container}>
            <Paper style={styles.paper} sx={{ overflow: 'hidden' }}>
                <h2>Agregar estimación</h2>
                <div style={styles.divInputs}>
                    <TextField
                        type='text'
                        style={{marginRight: '30px', maxWidth:'60px', flexGrow: 1}}
                        variant='outlined'
                        value={numAvances}
                        label='Estimación'
                        name='estimacion'
                        />
                    <TextField
                        label='Periodo Inicio'
                        style={styles.inputDates}
                        variant='outlined'
                        type='date'
                        defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
                        name = 'periodoInicio'
                        onChange={handleInputChange}
                        ></TextField>
                    <TextField
                        label='Periodo Final'
                        style={styles.inputDates}
                        variant='outlined'
                        type='date'
                        defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
                        name = 'periodoFinal'
                        onChange={handleInputChange}
                        ></TextField>
                    <TextField
                        type='text'
                        style={styles.input}
                        variant='outlined'
                        label='Importe sin IVA'
                        name='importe'
                        value={''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(importeValue))}
                        />
                    <TextField
                        label='Fecha Entrega'
                        style={styles.inputDates}
                        variant='outlined'
                        type='date'
                        defaultValue={dateFormat( Date.now(), "yyyy-mm-dd" )}
                        name = 'fechaEntrega'
                        onChange={handleInputChange}
                    ></TextField>
                </div>

                
                
                <TableContainer>
                    <Table className={classes.table} size='small'>

                        <TableHead>
                            <TableRow size='medium'>
                                <TableCell align='center' className={classes.tableCellBorder}>CLAVE</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>DESCRIPCION</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>UNIDAD</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD CATALOGO</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD EJERCIDA</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>PRECIO UNITARIO</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>MONTO CATALOGO</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>CANTIDAD ESTIMA</TableCell>
                                <TableCell align='center' className={classes.tableCellBorder}>MONTO ESTIMA</TableCell>
                            </TableRow>
                        </TableHead>




                {
                    docCat['resumen'].desccatres.map( (element) => {
                        // console.log(docCat['financiero']);
                        return( 
                            <TableBody>
                                <TableRow className={classes.tableHead}>
                                    <TableCell colSpan={1} className={classes.tableCell}>{(element.clave + '').toUpperCase()}</TableCell>
                                    <TableCell colSpan={8} className={classes.tableCell}>{element.cvacata}.- {(element.descripcion+ '').toUpperCase()}</TableCell>
                                </TableRow>

                                {
                                    docCat['detalle']['cvacata' + element.cvacata].map(element => {
                                        let cantidadUsada = getFinanciero(element?.cvacata, element?.cvadetalle);

                                        return (
                                            <TableRow className={classes.tableCellBorder}>
                                                <TableCell className={classes.tableCellBorder}>{(''+element.clave).toUpperCase()}</TableCell>
                                                <TableCell className={classes.tableCellBorder}>{element.cvacata}.{element.cvadetalle} {element.descripcion}</TableCell>
                                                <TableCell className={classes.tableCellBorder}>{(''+element.unidad).toUpperCase()}</TableCell>
                                                <TableCell align='center' className={classes.tableCellBorder}>{(''+element.cantidad).toUpperCase()}</TableCell>
                                                <TableCell align='center' className={classes.tableCellBorder}>{cantidadUsada}</TableCell>
                                                <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(element.punitario))}</TableCell>
                                                <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(element.monto))}</TableCell>
                                                
                                                {
                                                        (docCat['financiero']?.concluido?.hasOwnProperty('cvacata' + element.cvacata + 'cvadetalle' + element.cvadetalle)) &&
                                                        docCat['financiero']?.concluido['cvacata' + element.cvacata + 'cvadetalle' + element.cvadetalle]  ? 
                                                        <TableCell align='center'
                                                            className={classes.tableCellBorderAvance}>
                                                            Cerrado
                                                            </TableCell>
                                                        :
                                                        (element.cantidad - cantidadUsada) > 0 ?
                                                        <TableCell align='center'
                                                            className={classes.tableCellBorderAvance}>
                                                                <Input className={classes.tableInput} 
                                                                inputProps={{
                                                                    style: { textAlign: "right", 
                                                                    backgroundColor: '#E6E6E6',
                                                                }}} 
                                                                variant='filled' 
                                                                size='small'
                                                                type='number'
                                                                fullWidth
                                                                value={Number(estimacionConceptos['cvacata' + element.cvacata + 'cvadetalle' + element.cvadetalle] && estimacionConceptos['cvacata' + element.cvacata + 'cvadetalle' + element.cvadetalle].cantidad)}
                                                                onChange={e => handleEstimacionConcepto( element, cantidadUsada ,e)}>
                                                            </Input>
                                                        </TableCell>
                                                        : 
                                                        <TableCell align='center'
                                                            className={classes.tableCellBorderAvance}>
                                                            Concluido
                                                        </TableCell>
                                                }
                                                
                                                <TableCell align='right' className={classes.tableCellBorder}>{''+Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(Number(estimacionConceptos['cvacata' + element.cvacata + 'cvadetalle' + element.cvadetalle] ? estimacionConceptos['cvacata' + element.cvacata + 'cvadetalle' + element.cvadetalle].montoEstima : 0))}</TableCell>
                                            </TableRow>
                                                             
                                        )
                                    })
                                }
                            </TableBody>
                        )
                    })
                }
                
                    </Table>
                </TableContainer>

                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={disableButton}
                    style={styles.button}
                    startIcon={<AddCircleIcon />}
                    onClick={handleAgregarAvance}
                >Agregar</Button>
            </Paper>
        </div>
    )
}
