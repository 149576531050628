export class DocProyecto {
    constructor() {
        this.init();
    }

    setDato(e) {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'Finicio' || name === 'Ffinal') {
            let Fecha = new Date(value);
            Fecha.setDate(Fecha.getDate() + 1)
            let FechaTimeStamp = Fecha.getTime();
            this[name] = FechaTimeStamp;
        } else {
            this[name] = value;
        }

        console.log(this);
    }

    setConcepto(index, e) {
        let name = e.target.name;
        let value = (''+e.target.value).toUpperCase().trim();
        this.resumen.desccatres[index][name] = value;
        // console.log(this.resumen.desccatres);
    }

    setSubConcepto(conceptoId, subConceptoId , e) {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'finicio' || name === 'ftermino') {

            let Fecha = new Date(value);
            Fecha.setDate(Fecha.getDate() + 1)
            let FechaTimeStamp = Fecha.getTime();
            this.detalle[`cvacata${conceptoId}`][subConceptoId][name] = FechaTimeStamp;

        } else {
            this.detalle[`cvacata${conceptoId}`][subConceptoId][name] = value;
        }

        if (name === 'cantidad' || name === 'punitario') {
            this.calcSubConceptoMonto(conceptoId, subConceptoId)
        }
        // console.log(this.detalle[`cvacata${conceptoId}`]);
        

    }

    calcSubConceptoMonto(conceptoId, subConceptoId) {
        let cantidad = Number(this.detalle[`cvacata${conceptoId}`][subConceptoId].cantidad);
        let punitario = Number(this.detalle[`cvacata${conceptoId}`][subConceptoId].punitario);
        this.detalle[`cvacata${conceptoId}`][subConceptoId].monto = cantidad * punitario;
        // console.log(this.detalle[`cvacata${conceptoId}`][subConceptoId].monto);
        this.calcConceptoMonto(conceptoId);
    }

    calcConceptoMonto(conceptoId) {
        let total = 0;
        this.detalle[`cvacata${conceptoId}`].map((subconcepto) => {
            total += subconcepto.monto
        });
        this.resumen.desccatres[conceptoId-1].precio = total;
    }

    agregarParticipante(user) {
        this.usuarios[0] = user
    }

    agregarConcepto() {
        this.resumen.desccatres = [...this.resumen.desccatres, {cvacata: (this.resumen.desccatres.length+1), clave: ''  , descripcion: '', precio: 0 }];
        this.detalle[`cvacata${(this.resumen.desccatres.length)}`] = [];
        // console.log(this);
    }

    agregarSubConcepto(index) {
        this.detalle[`cvacata${index + 1}`].push({
            cvacata: (index + 1),
            cvadetalle: (this.detalle[`cvacata${index + 1}`].length + 1),
            clave: '',
            descripcion: '',
            unidad: '',
            cantidad: 0,
            punitario: 0,
            monto: 0,
            finicio: Date.now(),
            ftermino: Date.now(),

        });
        // console.log(this);
    }

    quitarSubConcepto(index) {
        this.detalle[`cvacata${index + 1}`].pop()
        console.log(this);
    }

    setProgramado(array) {
        this.avances.programado = array;
    }

    init() {
        this.Ffinal = Date.now()
        this.Finicio = Date.now()
        this.anioObra = new Date().getFullYear()
        this.creadorDoc = ''
        this.cvaObra = ''
        this.importContratoIva =''
        this.importContrato = ''
        this.municipio = ''
        this.nombreObra = ''
        this.rfcContratista = ''
        this.nombreContratista = ''
        this.repreLegal = ''
        this.ubicacionObra = ''
        this.usuarios = []
        this.avances = {
            financiero: 0,
            programado: 0,
            real: 0,
            lastUpdate: Date.now(),
        }
        this.resumen = {
            desccatres: [
                    {
                        cvacata: 1,
                        descripcion: '',
                        precio: 0
                    }
                ]
        }
        this.detalle = {
            cvacata1: []
        }
    }

    getDocInfo() {
        let Finicio = this.Finicio;
        let Ffinal = this.Ffinal;
        let anioObra = this.anioObra;
        let creadorDoc = this.creadorDoc;
        let cvaObra = this.cvaObra;
        let importContratoIva = this.importContratoIva;
        let importContrato = this.importContrato;
        let municipio = this.municipio;
        let nombreObra = this.nombreObra;
        let rfcContratista = this.rfcContratista;
        let nombreContratista = this.nombreContratista;
        let repreLegal = this.repreLegal;
        let ubicacionObra = this.ubicacionObra;
        let usuarios = this.usuarios;
        let avances = this.avances;
        return {
            Finicio,
            Ffinal,
            anioObra,
            creadorDoc,
            cvaObra,
            importContratoIva,
            importContrato,
            municipio,
            nombreObra,
            rfcContratista,
            nombreContratista,
            repreLegal,
            ubicacionObra,
            usuarios,
            avances,
        }    
    }

    getResumen() {
        return this.resumen;
    }

    getDetalle() {
        return this.detalle;
    }


}