import React from 'react';
//import {userContext, useEffect} from 'react';


//Paneles
import Usuario from '../components/Usuario'
import Administrador from '../components/Administrador';
import Director from '../components/Director';


//import jwtDecode from 'jwt-decode';

import firebase from "firebase/app";
import 'firebase/auth';

import '../utils/misFunciones.js'
//import { UserContext } from "../utils/UserProvider"
import Loading from './loading';
import Perfil from '../components/Perfil';
function Home() {
    
    const [component, setComponet] = React.useState(<Loading></Loading>);
    
//Verificar sesion iniciada

React.useEffect(()=>{
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            firebase.auth().currentUser.getIdTokenResult(true)
             .then((idTokenResult) =>{
                 console.log(idTokenResult.claims);
                 if(idTokenResult.claims.AdminAccount){

                    setComponet(<Administrador user={user}></Administrador>);
                 
                 }else if(idTokenResult.claims.DirectorAccount){
                 
                    setComponet(<Director user={user}></Director>);
                 
                 }else if(idTokenResult.claims.OperadorAccount){
                    
                    setComponet(<Usuario user={user} userID = {user.uid}></Usuario>);
                 }else{
                    firebase.auth().signOut().then(() => {
                        // Sign-out successful.
                      }).catch((error) => {
                        // An error happened.
                      });
                 } 
             } )
        }
    });
}, [])


    return (
        <div>
            {component}         
        </div>
    )

}

export default Home
