import React from 'react'
import {/*initialState,*/ useEffect, useState} from 'react';
import { /*withStyles,*/ makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import dateFormat from 'dateformat'
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
/*
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
*/



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AvatarUser from '../AvatarUser';
import { Grid } from '@material-ui/core';
import { ProgressBarComponent } from '../ProgressBarComponent';
import { ChatComponent } from '../usuario/ChatComponent';


const useStyles = makeStyles((theme)=>({
    table: {
      borderColor: '#D8D8D8',
      border: '2px solid',
      marginBottom: '30px',
      maxWidth: '100%',
    },
    tableHead: {
      borderColor: theme.palette.secondary.main,
      border: '2px solid',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.text,
    },
    tableCell: {
      color: theme.palette.secondary.text,
    },
    tableCellBorder:{
      borderColor: '#D8D8D8',
      border: '2px solid',
      padding: '5px 2px',
      margin: '2px',

    },
    tableAbancesCellBorder:{
      width: '50px'

    },
    tableCellBorderAvance:{
      borderColor: '#D8D8D8',
      border: '2px solid',
      width: '150px',
      padding: '0px',
      margin: '0px'
    },
    tableInput:{
        textAlign: 'center',
        backgroundColor: '#E6E6E6',
        margin: 0,
        padding: 0,
        maxWidth: '62px',
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonDelete: {
      margin: 'auto',
      marginRight: 0,
    },
    contenedorNombre:{
      textAlign: 'center',
      display: 'flex',
      maxWidth: '100%'
    },
    GridFechas: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
    },
  }));



export default function InfoProyectoD(props) {

    const classes = useStyles();
    const [loading, setloading] = useState(false);

    const [docInfo, setdocInfo] = useState(null);
    const [docCat, setdocCat] = useState(null);
    var nuevoAvance = {};
    const [nuevoAvanceC, setNuevoAvanceC] = useState(nuevoAvance); 
    const [permitirGuardar, setPermitirGuardar] = useState(false);

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('proyectos').doc(props.idDoc)
          .onSnapshot(snapshot => {
            setdocInfo( snapshot.data());
            console.log('Descarga. Doc id: ', props.idDoc);
          })
        const getCat = firebase.firestore().collection('proyectos').doc(props.idDoc).collection('cat')
          .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map(doc => ({idDoc: doc.id, ...doc.data()}));
              
              console.log(data);
              console.log("DECARGANDO DOCC");
              setdocCat(data);
              setNuevoAvanceC(data[0]);
            })
        return () => {
          unsubscribe();
          getCat();
        }
      }, [firebase])

      /*
      const estructura = {
        '1':{
          '1':{
            avances:[
              {
                cantidad: 20
              }
            ]
          }
        } 
      }

      */
  
      
     

    if(docInfo!=null){
      var date;
        return ( 
            
            <div>
                <div className={classes.contenedorNombre}>
                  <h3>{docInfo.anioObra} {docInfo.cvaObra} .- {docInfo.nombreObra}</h3>
                </div>
                <hr/>
                <div>
                <h4 className='DatosProyTitulo'>Ubicación: {docInfo.ubicacionObra}, {docInfo.municipio}</h4>
                <h4 className='DatosProyTitulo'>Importe de contrato con IVA: {docInfo.importContratoIva ? `${Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(docInfo.importContratoIva)}` : 'Sin dato'}</h4>
                <h4 className='DatosProyTitulo'>Contratista: {docInfo.nombreContratista && docInfo.nombreContratista} / {docInfo.repreLegal && docInfo.repreLegal}</h4>
              </div>
              <Grid container spacing={1} >
                  <Grid item xs className={classes.GridFechas}>
                      <p>Fecha inicio: {(!isNaN(parseInt(docInfo.Finicio)) && docInfo.Finicio)
                          && dateFormat(date = new Date(parseInt(docInfo.Finicio) * 1), "dd mmmm, yyyy")}
                      </p>
                      <p>Fecha de término: {(!isNaN(parseInt(docInfo.Ffinal)) && docInfo.Ffinal)
                          && dateFormat(date = new Date(parseInt(docInfo.Ffinal) * 1), "dd mmmm, yyyy")}
                      </p>
                  </Grid>
              </Grid>
              <hr />
                <h4>Residente:</h4>
                <div>
                    {
                        docInfo.usuarios.map((user, i) =>{
                            return(<Chip
                                avatar={<AvatarUser username={user}></AvatarUser>}
                                label={user}
                                clickable
                                color="primary"
                                deleteIcon={<DoneIcon />}
                                variant="outlined"
                              />)
                        })
                    }
                </div>
                <hr/>



                {(() => {
                    if (docCat!=null) {
                    return (
                        <div>
                                        
                            <TableContainer>
                                <Table className={classes.table} size='small'>
                                    <TableHead>
                                        <TableRow size='medium'>
                                            <TableCell align='center' className={classes.tableCellBorder}>DESCRIPCION</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorder}>MONTO</TableCell>
                                            <TableCell align='center' className={classes.tableCellBorderAvance}>AVANCE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    
                            {
                                docCat[docCat.length-1].desccatres.map((element) => {
                                    
                                    return(
                                        
                                        <TableBody>
                                            <TableRow className={classes.tableHead}>
                                                <TableCell colSpan={3} className={classes.tableCell}>{element.cvacata}.- {element.descripcion.toUpperCase()}</TableCell>
                                      
                                            </TableRow>
                                                { 
                                                    
                                                    
                                                    docCat[docCat.length-2]['cvacata'+element.cvacata].map(element => {

                                        
                                                        return(
                                                            <TableRow className={classes.tableCellBorder}>
                                                                <TableCell className={classes.tableCellBorder}>{element.cvacata}.{element.cvadetalle} {element.descripcion}</TableCell>
                                                                <TableCell align='right' className={classes.tableCellBorder}>{Intl.NumberFormat('en-US', {style: "currency", currency: "USD",}).format(element.precio)}</TableCell>
                                                                <TableCell align='right' 
                                                                  className={classes.tableCellBorderAvance}>
                                                                      {nuevoAvanceC[element.cvacata] && Number(nuevoAvanceC[element.cvacata][element.cvadetalle].avances[0].cantidad).toFixed(2) + '%'}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                        </TableBody>
                                        )
                                
                                })
                                                
                            }   
                          </Table>
                          

                          

                        </TableContainer>
                        <TableContainer>
                          <Table className={classes.table} size='small'>
                            <TableHead>
                              <TableRow className={classes.tableHead} size='medium'>
                                <TableCell colSpan={3} className={classes.tableCell}>AVANCES</TableCell>
                              </TableRow>
                            </TableHead>



                            
                            <TableBody>
                              <TableRow className={classes.tableCellBorder}>
                                <TableCell className={classes.tableAbancesCellBorder}>
                                  Real
                                </TableCell>
                                <TableCell>
                                  <ProgressBarComponent text='' basic={true} value={(docInfo.avances.real && docInfo.avances) ? docInfo.avances.real : 0} color={(((docInfo.avances.real && docInfo.avances) ? docInfo.avances.real : 0) >= ((docInfo.avances.programado && docInfo.avances) ? docInfo.avances.programado : 0))?'green':'red'}/>
                                </TableCell>
                                <TableCell align='right'>{(docInfo.avances.real && docInfo.avances) ? (Number(docInfo.avances.real).toFixed(2) + '%') : ''}</TableCell>
                              </TableRow>
                              <TableRow className={classes.tableCellBorder}>
                                <TableCell>
                                  Programado
                                </TableCell>
                                <TableCell>
                                  <ProgressBarComponent text='' basic={true} value={(docInfo.avances.programado && docInfo.avances) ? docInfo.avances.programado : 0} color='blue' />
                                </TableCell>
                                <TableCell className={classes.tableAbancesCellBorder} align='right'>{(docInfo.avances.programado && docInfo.avances) ? (Number(docInfo.avances.programado).toFixed(2) + '%') : ''}</TableCell>
                              </TableRow>
                              <TableRow className={classes.tableAbancesCellBorder}>
                                <TableCell>
                                  Financiero
                                </TableCell>
                                <TableCell>
                                  <ProgressBarComponent text='' basic={true} value={(docInfo.avances.financiero && docInfo.avances) ? docInfo.avances.financiero : 0} color='brown' />
                                </TableCell>
                                <TableCell align='right'>{(docInfo.avances.financiero && docInfo.avances) ? (Number(docInfo.avances.financiero).toFixed(2) + '%') : ''}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                            
                        </div>
                    )
                    } else {
                        return (
                            <div>Cargando...</div>
                        )
                    }
                })()}

              
              <ChatComponent docId={props.idDoc}/>
              <br />
              <br />
            </div>
        )
    
    }else{
        return(
            <div></div>
        )
    }

}
