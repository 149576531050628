import React from 'react'

import firebase from 'firebase';

import {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import AvatarUser from './AvatarUser';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatarStyle: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: 'auto',
      backgroundColor: theme.palette.primary.main
    },
    paperContent: {
      padding: theme.spacing(8),
      border: '2px solid',
      borderColor: theme.palette.primary.main,


    },
    contenedor : {
      width: '60%',
      maxWidth: '700px',
      minWidth: '340px',
      margin: 'auto',
    },
    divUsername: {
      textAlign: 'center'
    },
    paperContentInfo: {
      padding: theme.spacing(3),
      backgroundColor: '#F2F2F2'
    },

  }));

export default function Perfil( props ) {

    const classes = useStyles();
    const [userData, setUserData] = useState(null)
  
    useEffect(() => {
      
      console.log(props.username)
      const getUserData = firebase.firestore().collection('userData').doc(props.username).onSnapshot((doc) => {
          setUserData(doc.data());
          console.log(userData)
          console.log('documento descargado')
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

    return () => {
        getUserData()
      }
    }, [firebase])



    return (
        <div>
            <div className={classes.contenedor}>
                <Paper className= {classes.paperContent}>
                    <AvatarUser username={props.username} className={classes.avatarStyle}></AvatarUser>
                    <div className={classes.divUsername}>

                    {
                      (()=>{
                        if(userData !== null){
                          return(<h2>{props.username} - {
                            (userData.tipo === 'usuario') ? 'Residente' :
                            (userData.tipo === 'supervisor') ? 'Supervisor' :
                            (userData.tipo === 'director') ? 'Director' :
                            (userData.tipo === 'admin') ? 'Administrador' : userData.tipo
                        
                        }</h2>)
                          
                        }else{
                          <h2>{props.username}</h2>
                        }
                      })()
                    }
                      
                    </div>
                    <Paper className={classes.paperContentInfo}>
                      {(()=>{

                        if(userData !== null){
                          return(
                            <div>        
                              <h3>Nombre:</h3>
                              <p>{userData.nombre}</p>
                              <h3>Cargo:</h3>
                              <p>{userData.cargo}</p> 
                            </div>     
                          )
                        }else{
                          <div>
                            cargando..
                          </div>
                        }

                       })()
      
                      }
                    </Paper>        
                </Paper>
                </div>
        </div>
    )
}
