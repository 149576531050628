import { LinearProgress, makeStyles, styled } from '@material-ui/core'
import React from 'react'


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    alignSelf: 'center',
  }));


export const ProgressBarComponent = ({ text, value, color, basic = false}) => {

    
const useStyles = makeStyles((theme) => ({
    DivContainer: {
        display: 'flex',
        alignContent:'center',
    },
    Texto: {
        width: '0%',
        minWidth:'95px'
    },
    Porcentaje: {
        minWidth: '52px'
    },
    ProgressBar: {
        width: '100%'
    },
    root: {

        "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
          backgroundColor: "#D6D7D8"
        },
        "& .MuiLinearProgress-colorPrimary": {
          backgroundColor: "#D6D7D8"
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: (
                (color === 'blue') ? '#004273' :
                (color === 'green') ? '#1A7300': 
                (color === 'red') ? '#D61202': 
                (color === 'brown') ? '#D66F02': '#004273'
            )
        }
    }
}))

    const classes = useStyles();


    return (
        <div className={classes.DivContainer}>
            {basic ? '' : <h4 className={classes.Texto}>{text}</h4>}
            {basic ? '' : <h4 className={classes.Porcentaje}>{Number(value).toFixed(2)}%</h4>}
            <BorderLinearProgress className={classes.ProgressBar}
                variant="determinate"
                value={Number(value)}
                classes={{
                    root: classes.root
                  }}
            />
        </div>
    )
}
