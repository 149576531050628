import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';

import { MuiThemeProvider } from '@material-ui/core/styles';
//import{createMuiTheme }from '@material-ui/core/styles/createMuiTheme';
import{ unstable_createMuiStrictModeTheme as createMuiTheme }from '@material-ui/core';


import AuthRoute from './utils/AuthRoute'
import PrivateRoute from './utils/PrivateRoute'


//pages
import home from './pages/home';
import login from './pages/login';


import { useContext, useEffect, useReducer } from 'react';

import firebase from 'firebase/app';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';
import { Redirect } from 'react-router-dom';
import loading from './pages/loading';

import { html2pdf } from 'html2pdf.js';
// Tema de la pagina 
// Info en https://material-ui.com/customization/palette/

// #9D2449
// #3f51b5


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9D2449',
    },
    secondary: {
      light: '#0066ff',
      main: '#b38e5d',
      contrastText: '#ffcc00',
      text: '#fff'
    },
    botonDelete:{
      main: '#8D0703',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});



function App() {
  
  // Estado inicial de la sesion
  const init = () => {
    return JSON.parse(localStorage.getItem('avanproyIsUserLogged')) || { sessionState: false };
  }

  // Creacion del reducer
  const [user, dispatch] = useReducer(authReducer, {}, init);

  
  useEffect(() => {
    let unsuscribe = firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        dispatch({
          type: 'SESION_INICIADA',
          payload: {user}
        })
        localStorage.setItem('avanproyIsUserLogged', JSON.stringify({ sessionState: true }));
      } else {
        dispatch({
          type: 'SESION_FINALIZADA',
        })
        localStorage.setItem('avanproyIsUserLogged', JSON.stringify({ sessionState: false }));
      }
    });
    return unsuscribe
  }, [])


  return (


    <AuthContext.Provider value={{ user, dispatch }}>
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <div className='container'>
              <Switch>
                <PrivateRoute exact path='/' component={home} authenticated={user.sessionState}></PrivateRoute> 
                <PrivateRoute exact path='/proyectos' component={loading} authenticated={user.sessionState}></PrivateRoute> 
                <AuthRoute exact path='/login' component={login} authenticated={user.sessionState}></AuthRoute> 
                <Route path="*"><Redirect to="/" /></Route>
              </Switch>
            </div>
          </Router>
        </div>
      </MuiThemeProvider> 
    </AuthContext.Provider>
    
  );

}

export default App;
